import { AnalyticsTrigger, Button as TeliaReactButton, IButtonProps } from 'telia-front-react';
import React from 'react';

export type ButtonProps = {
  gaLabel?: string;
} & IButtonProps;

export const Button = (props: ButtonProps) =>
  props.gaLabel ? (
    <AnalyticsTrigger
      trigger={{
        callback: 'onClick',
        id: `Link - ${props.gaLabel}`,
      }}
    >
      <TeliaReactButton {...props} />
    </AnalyticsTrigger>
  ) : (
    <TeliaReactButton {...props} />
  );
