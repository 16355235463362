import { Usage } from '../model/Usage';
import { DataAmount } from './DataAmount';
import { euDataTariffClasses, LimitType } from '../model/customerProduct/usage/Usage';

export class DataUsage extends Usage<DataAmount> {
  used = new DataAmount(this.resource.used);
  remaining = new DataAmount(this.resource.remaining);
  limit = new DataAmount(this.resource.limit);

  get labelKey() {
    return euDataTariffClasses.includes(this.tariffClass) ? 'my-services.data.eu' : 'my-services.data.used-from-quota';
  }

  get isSeventyPercentageUsed(): boolean {
    return this.limitType !== LimitType.UNLIMITED && this.used.bytes >= this.limit.bytes * 0.7;
  }

  get isLimitReached(): boolean {
    return this.remaining.bytes <= 0;
  }
}
