/* istanbul ignore file */
export const enum ProductState {
  ACTIVE = 'ACTIVE',
  PENDING_ACTIVE = 'PENDING_ACTIVE',
  PENDING_TERMINATE = 'PENDING_TERMINATE',
  TERMINATED = 'TERMINATED',
  SUSPENDED = 'SUSPENDED',
  CANCELLED = 'CANCELLED',
  PENDING_SUSPEND = 'PENDING_SUSPEND',
  PENDING_CANCEL = 'PENDING_CANCEL',
  CLOSED = 'CLOSED',
}
