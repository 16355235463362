export enum ActionCode {
  ADD = 'ADD',
  ADD_FOR_CHANGE = 'ADD_FOR_CHANGE',
  ADD_FOR_DELETION = 'ADD_FOR_DELETION',
  ADD_FOR_REUSE = 'ADD_FOR_REUSE',
  ADD_MEMBER = 'ADD_MEMBER',
  BUY_OUT = 'BUY_OUT',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CANCEL_CHANGE_CUSTOMER_REQ = 'CANCEL_CHANGE_CUSTOMER_REQ',
  CHANGE = 'CHANGE',
  CHANGE_ACCOUNT = 'CHANGE_ACCOUNT',
  CHANGE_AGREEMENT_NUMBER = 'CHANGE_AGREEMENT_NUMBER',
  CHANGE_ALIAS = 'CHANGE_ALIAS',
  CHANGE_CALLS_AND_MESSAGES_LIMIT = 'CHANGE_CALLS_AND_MESSAGES_LIMIT',
  CHANGE_CUSTOMER = 'CHANGE_CUSTOMER',
  CHANGE_CUSTOMER_REQ = 'CHANGE_CUSTOMER_REQ',
  CHANGE_DATA_LIMIT = 'CHANGE_DATA_LIMIT',
  CHANGE_OFFERING = 'CHANGE_OFFERING',
  CHANGE_PACKAGE = 'CHANGE_PACKAGE',
  CHANGE_SHARED_DATA_LIMIT = 'CHANGE_SHARED_DATA_LIMIT',
  DELETE = 'DELETE',
  DELETE_FOR_CHANGE = 'DELETE_FOR_CHANGE',
  DIAGNOSTICS = 'DIAGNOSTICS',
  HANDOUT = 'HANDOUT',
  MANAGE = 'MANAGE',
  MANAGE_ROUTER = 'MANAGE_ROUTER',
  MANAGE_SERVICES = 'MANAGE_SERVICES',
  MANAGE_SIM = 'MANAGE_SIM',
  MANAGE_USER = 'MANAGE_USER',
  MANAGE_VOIP = 'MANAGE_VOIP',
  MANAGE_VOIP_GROUP = 'MANAGE_VOIP_GROUP',
  MOBILE_FORWARDING = 'MOBILE_FORWARDING',
  MOVE = 'MOVE',
  NO_ACTIONS = 'NO_ACTIONS',
  ORDER_DATA = 'ORDER_DATA',
  ORDER_MONTHLY_DATA = 'ORDER_MONTHLY_DATA',
  RESUME = 'RESUME',
  RESUME_DEBT = 'RESUME_DEBT',
  RETURN = 'RETURN',
  SHOW_CALL_DISTINCTION = 'SHOW_CALL_DISTINCTION',
  SHOW_OFFERING_DETAILS = 'SHOW_OFFERING_DETAILS',
  SHOW_ROAMING_INFO = 'SHOW_ROAMING_INFO',
  SUSPEND = 'SUSPEND',
  SUSPEND_DEBT = 'SUSPEND_DEBT',
  VIEW_CONTRACT = 'VIEW_CONTRACT',
  VIEW_ORDER = 'VIEW_ORDER',
  VIEW_DATA_USAGE_HISTORY = 'VIEW_DATA_USAGE_HISTORY',
  VIEW_ELIS_CONTRACT = 'VIEW_ELIS_CONTRACT',
}
