import { ExternalLinks } from '../../model/ExternalLink';
import CacheableAxiosRequestConfig from '../../util/CacheableAxiosRequestConfig';
import { API } from '../../util/apiUtil';

export const CONFIG_API_BASE_URL = '/sales-api/config';

class ConfigApi {
  static getExternalLinks = (): Promise<ExternalLinks> => {
    const config: CacheableAxiosRequestConfig = { cache: true };

    return API.get<ExternalLinks>(CONFIG_API_BASE_URL + '/external-links', config).then((response) => response.data);
  };
}

export default ConfigApi;
