import { toDate } from '../util/dateUtil';
import { UsageResource } from './customerProduct/usage/Usage';
import { DataAmount } from '../components/DataAmount';
import { Usage } from './Usage';

export class RoamingDataUsage extends Usage<DataAmount> {
  used = new DataAmount(this.resource.used);
  remaining = new DataAmount(this.resource.remaining);
  limit = new DataAmount(this.resource.limit);
  validUntil = toDate(this.resource.validUntil);

  constructor(resource: UsageResource) {
    super(resource);
  }
}
