import { getPubSubUrl } from '../../util/getPubSubUrl';
import { Environment } from '../../model/config/Environment';
import { PubSubConnectionStore, PubSubConnectionStoreContext } from '../PubSubConnectionStore';

export class CustomerProductsPubSubConnectionStore extends PubSubConnectionStore {
  protected connectionUrl = getPubSubUrl(this.environment);

  constructor(private environment: Environment, context: PubSubConnectionStoreContext) {
    super(context);
  }
}
