/* istanbul ignore file */
import { CategoryCode } from './CategoryCode';

export type CustomerProductRootCategoryCode =
  | CategoryCode.INTERNET_SERVICES
  | CategoryCode.TV_SERVICES
  | CategoryCode.TV_DEVICES
  | CategoryCode.PHONE_SERVICES
  | CategoryCode.FIX_SOLUTIONS
  | CategoryCode.DEVICES
  | CategoryCode.INTERNET_DEVICES
  | CategoryCode.NEWSSTAND
  | CategoryCode.SPOTIFY
  | CategoryCode.SAFE
  | CategoryCode.DEVICE_INSURANCE
  | CategoryCode.MOBILE_PACKAGES
  | CategoryCode.MOBILE_GROUPS
  | CategoryCode.SMARTWIFI_TEENUSED
  | CategoryCode.OTHER_SERVICES
  | CategoryCode.MOBILE_FRAMEWORK_AGREEMENTS
  | CategoryCode.TELIA_PLAY
  | CategoryCode.FINANCE_SERVICES
  | CategoryCode.HOSTING_SERVICES
  | CategoryCode.IT_SERVICES;

export type CustomerProductChildCategoryCode =
  | CategoryCode.INTERNET_EXTRA_SERVICES
  | CategoryCode.TV_SCREENS
  | CategoryCode.TV_BASE_PACKAGES
  | CategoryCode.INTERNET_SPEEDS
  | CategoryCode.TV_VOD_LIMITS;

const arrayOfAllCustomerProductRootCategoryCodes = <T extends CustomerProductRootCategoryCode[]>(
  ...codes: T & ([CustomerProductRootCategoryCode] extends [T[number]] ? unknown : 'Invalid')
) => codes;

const arrayOfAllCustomerProductChildCategoryCodes = <T extends CustomerProductChildCategoryCode[]>(
  ...codes: T & ([CustomerProductChildCategoryCode] extends [T[number]] ? unknown : 'Invalid')
) => codes;

export const customerProductRootCategoryCodes = arrayOfAllCustomerProductRootCategoryCodes(
  CategoryCode.INTERNET_SERVICES,
  CategoryCode.TV_SERVICES,
  CategoryCode.TV_DEVICES,
  CategoryCode.PHONE_SERVICES,
  CategoryCode.FIX_SOLUTIONS,
  CategoryCode.DEVICES,
  CategoryCode.INTERNET_DEVICES,
  CategoryCode.NEWSSTAND,
  CategoryCode.SPOTIFY,
  CategoryCode.SAFE,
  CategoryCode.DEVICE_INSURANCE,
  CategoryCode.MOBILE_PACKAGES,
  CategoryCode.MOBILE_GROUPS,
  CategoryCode.SMARTWIFI_TEENUSED,
  CategoryCode.OTHER_SERVICES,
  CategoryCode.MOBILE_FRAMEWORK_AGREEMENTS,
  CategoryCode.TELIA_PLAY,
  CategoryCode.FINANCE_SERVICES,
  CategoryCode.HOSTING_SERVICES,
  CategoryCode.IT_SERVICES
);

export const customerProductChildCategoryCodes = arrayOfAllCustomerProductChildCategoryCodes(
  CategoryCode.INTERNET_EXTRA_SERVICES,
  CategoryCode.TV_SCREENS,
  CategoryCode.TV_BASE_PACKAGES,
  CategoryCode.INTERNET_SPEEDS,
  CategoryCode.TV_VOD_LIMITS
);

export const getCustomerProductTypeByRootCategory = (categoryCode: CustomerProductRootCategoryCode) => {
  switch (categoryCode) {
    case CategoryCode.TV_SERVICES:
    case CategoryCode.TV_DEVICES:
    case CategoryCode.TELIA_PLAY:
      return 'tv';
    case CategoryCode.INTERNET_SERVICES:
    case CategoryCode.SMARTWIFI_TEENUSED:
    case CategoryCode.FIX_SOLUTIONS:
    case CategoryCode.INTERNET_DEVICES:
      return 'internet';
    case CategoryCode.DEVICES:
      return 'rental';
    case CategoryCode.MOBILE_PACKAGES:
    case CategoryCode.MOBILE_GROUPS:
    case CategoryCode.MOBILE_FRAMEWORK_AGREEMENTS:
      return 'mobile';
    case CategoryCode.PHONE_SERVICES:
    case CategoryCode.NEWSSTAND:
    case CategoryCode.SPOTIFY:
    case CategoryCode.SAFE:
    case CategoryCode.DEVICE_INSURANCE:
    case CategoryCode.OTHER_SERVICES:
      return 'vas';
    case CategoryCode.FINANCE_SERVICES:
      return 'finance';
    case CategoryCode.HOSTING_SERVICES:
      return 'hosting';
    case CategoryCode.IT_SERVICES:
      return 'it';
    /* istanbul ignore next */
    default:
      // You must associate all root category codes with a type
      const assertNever = (c: never) => {};
      return assertNever(categoryCode);
  }
};
export type CustomerProductType = Exclude<ReturnType<typeof getCustomerProductTypeByRootCategory>, void>;
