import {
  action,
  computed,
  IMapDidChange,
  IReactionDisposer,
  makeObservable,
  observable,
  ObservableMap,
  observe,
  reaction,
  when,
} from 'mobx';
import { INoticeProps } from 'telia-front-react';
import React from 'react';
import { Observer } from 'mobx-react';
import CustomerProduct from '../../model/customerProduct/CustomerProduct';
import CustomerProductsHolder from '../../model/customerProduct/CustomerProductsHolder';
import { ProductSearchFilter } from '../../model/product/productSearchFilter/ProductSearchFilter';
import CustomerProductCharacteristic from '../../model/customerProduct/Characteristic';
import { IndexSearchConstraints } from '../../model/product/indexSearchConstraints/IndexSearchConstraints';
import { formatDate } from '../../util/dateUtil';
import { Api, readProductSummaries } from '../../service/Api';
import ErrorStore from '../error/ErrorStore';
import CrmStore from '../crm/CrmStore';
import LocaleStore from '../locale/LocaleStore';
import FeatureStore from '../feature/FeatureStore';
import NavigationStore from '../navigation/NavigationStore';
import SsoStore from '../sso/SsoStore';
import TranslateStore from '../translate/TranslateStore';
import ProductUserStore from '../productUser/ProductUserStore';
import MandateCheckerStore from '../mandateChecker/MandateCheckerStore';
import { AccessData } from '../../model/AccessData';
import { ErrorUtil } from '../../util/ErrorUtil';
import { Analytics } from '../../util/Analytics';
import { isApiResponseWithError, isCancelledResponse } from '../../util/apiUtil';
import { HttpStatusCode } from '../../model/api/HttpStatusCode';
import { CatalogCode } from '../../model/CatalogCode';
import { CategoryCode } from '../../model/CategoryCode';
import { ProductRef } from '../../components/ProductRef';
import { CustomerProductSummary } from '../../model/CustomerProductSummary';
import { CharacteristicCode } from '../../model/CharacteristicCode';
import { isArrayOfCategoryCodes } from '../../util/isArrayOfCategoryCodes';
import { isArrayOfProductRefs } from '../../util/isArrayOfProductRefs';
import { ProductSearchConstraints } from '../../model/ProductSearchConstraints';
import { ProductDescriptor } from '../../model/ProductDescriptor';
import { ProductDescriptorArray } from '../../model/ProductDescriptorArray';
import { MessageStore } from '../MessageStore';
import { Message } from '../../components/Message';
import { MessageKey } from '../../components/MessageKey';

export default class ProductStore {
  @observable private productsMap: ObservableMap<ProductRef['productIdAndOrigin'], CustomerProduct> = new ObservableMap();
  @observable productsAccessDataResponse?: AccessData;
  private productReactionDisposers: Map<ProductRef['productIdAndOrigin'], IReactionDisposer> = new Map();
  private summaryRequestCancellers: Array<() => void> = [];

  constructor(
    private rootStore: {
      errorStore: ErrorStore;
      crmStore: CrmStore;
      navigationStore: NavigationStore;
      ssoStore: SsoStore;
      translateStore: TranslateStore;
      mandateCheckerStore: MandateCheckerStore;
      productUserStore: ProductUserStore;
      featureStore: FeatureStore;
      messageStore: MessageStore;
      localeStore: LocaleStore;
    }
  ) {
    makeObservable(this);
    reaction(
      () => this.rootStore.ssoStore.customerId,
      () => this.clear()
    );
    reaction(
      () => this.rootStore.localeStore.currentLocale,
      () => /* istanbul ignore next */ this.updateProductLocales()
    );
    observe(this.productsMap, this.handleProductsMapChange);
  }

  get products(): CustomerProduct[] {
    return Array.from(this.productsMap.values());
  }

  getProduct(productRef: ProductRef): CustomerProduct | undefined {
    return this.productsMap.get(productRef.productIdAndOrigin);
  }

  async loadProducts(
    customerId: number | null,
    catalogCode: CatalogCode | null,
    searchFilter: ProductSearchFilter | undefined,
    endAfter: string | undefined,
    rootOnly: boolean,
    page: number,
    size: number,
    excludeProductsWithNextProduct: boolean | undefined
  ): Promise<CustomerProductsHolder | undefined> {
    /* istanbul ignore else */
    if (isArrayOfProductRefs(searchFilter)) {
      return this.loadProductsByProductRefs(customerId, catalogCode, searchFilter, endAfter, rootOnly);
    } else if (!customerId) {
      throw new Error('loadProducts called without ArrayOfProductRefs and customerId: ' + JSON.stringify(arguments));
    }

    const productsPage = await this.fetchProducts(
      customerId,
      catalogCode,
      searchFilter,
      endAfter,
      rootOnly,
      page,
      size,
      excludeProductsWithNextProduct
    );

    if (!productsPage) {
      return undefined;
    }

    this.addProducts(productsPage.products);

    return productsPage;
  }

  async loadProductsCount(searchConstraints: IndexSearchConstraints): Promise<number | undefined> {
    const { data } = await Api.indexedProducts({ params: { searchConstraints: JSON.stringify(searchConstraints) } }).load();
    return data?.totalElements;
  }

  @action
  setAccessData(accessData?: AccessData) {
    this.productsAccessDataResponse = accessData;
  }

  get accessData(): AccessData | undefined {
    return this.productsAccessDataResponse as AccessData;
  }

  @computed
  get indexSearchConstraints(): IndexSearchConstraints | undefined {
    const { hasDisplayableProductUsers, displayableProductUsers } = this.rootStore.productUserStore;
    const { hasPageMandate } = this.rootStore.mandateCheckerStore;
    const { customerId } = this.rootStore.ssoStore;

    return {
      size: 25,
      customerId: hasPageMandate ? customerId : undefined,
      accountIds: undefined,
      products:
        !hasDisplayableProductUsers || hasPageMandate
          ? undefined
          : {
            productIds: displayableProductUsers.map(({ productId }) => productId),
            origins: [...new Set(displayableProductUsers.map(({ origin }) => origin))],
          },
      applyFiltersByJwtPermissions: true,
    };
  }

  indexSearchConstraintsWithProductUsers = (): IndexSearchConstraints | undefined => {
    const indexSearchConstraints = this.indexSearchConstraints;
    const { displayableProductUsers } = this.rootStore.productUserStore;
    return !!indexSearchConstraints
      ? ({
        ...indexSearchConstraints,
        products: {
          productIds: displayableProductUsers.map((productUser) => productUser.productId),
          origins: [...new Set(displayableProductUsers.map((productUser) => productUser.origin))],
        },
      } as IndexSearchConstraints)
      : undefined;
  };

  async updateProductsByProductRef(catalogCode: CatalogCode, expiredProduct: CustomerProduct, rootOnly = true): Promise<void> {
    const productsPage = await this.fetchProducts(null, catalogCode, [expiredProduct], undefined, rootOnly, 1, 1, undefined);
    const product = productsPage?.products[0];

    if (product) {
      await product.loadDetails();
      await when(() => product.isDetailsReady);
      product.setDetailsData(expiredProduct.productDetailsData);
      if (product.isMobileGroupNumber) {
        const rootProduct = this.getProduct(ProductRef.fromProductIdAndOrigin(product.parent!.productIdAndOrigin))!!;
        rootProduct.children.replace(
          rootProduct.children.map((child) => (child.productIdAndOrigin === product.productIdAndOrigin ? product : child))
        );
        this.addProducts([rootProduct]);
      } else {
        this.addProducts([product]);
      }
    }
  }

  private async loadProductsByProductRefs(
    customerId: number | null,
    catalogCode: CatalogCode | null,
    productRefs: ArrayOfAtLeastOne<ProductRef>,
    endAfter: string | undefined,
    rootOnly: boolean
  ): Promise<CustomerProductsHolder | undefined> {
    const hasNextPage = false;
    const page = 1;
    const size = productRefs.length;
    const productsPage = await this.fetchProducts(customerId, catalogCode, productRefs, endAfter, rootOnly, page, size, undefined);

    if (!productsPage) {
      return undefined;
    }

    this.addProducts(productsPage.products);

    return {
      products: productsPage.products,
      hasNextPage,
    };
  }

  private async fetchProducts(
    customerId: number | null,
    catalogCode: CatalogCode | null,
    searchFilter: ProductSearchFilter | undefined,
    endAfter: string | undefined,
    rootOnly: boolean,
    page: number,
    size: number,
    excludeProductsWithNextProduct: boolean | undefined
  ): Promise<CustomerProductsHolder | undefined> {
    const searchConstraints = this.getSummarySearchConstraints(
      customerId,
      catalogCode,
      searchFilter,
      endAfter,
      rootOnly,
      excludeProductsWithNextProduct
    );
    const { cancelRequest, responsePromise } = readProductSummaries(searchConstraints, size, page);
    this.summaryRequestCancellers.push(cancelRequest);
    const response = await responsePromise;
    this.summaryRequestCancellers.splice(this.summaryRequestCancellers.indexOf(cancelRequest), 1);

    if (isApiResponseWithError(response)) {
      const [error] = response.errors;
      if (!isCancelledResponse(response) && error.status !== HttpStatusCode.SESSION_EXPIRED && !endAfter) {
        throw new Error('Failed to fetch products by customer ID: ' + error.title);
      }
      return undefined;
    }

    // @ts-ignore
    this.setAccessData(response.data.accessData as AccessData);

    return new CustomerProductsHolder(response.data);
  }

  cancelProductSummariesRequests = () => this.summaryRequestCancellers.forEach((cancel) => cancel());

  private getSummarySearchConstraints(
    customerId: number | null,
    catalogCode: CatalogCode | null,
    searchFilter: ProductSearchFilter | undefined,
    endAfter: string | undefined,
    rootOnly: boolean,
    excludeProductsWithNextProduct: boolean | undefined
  ): ProductSearchConstraints {
    const searchConstraints: ProductSearchConstraints = {
      customerId,
      productCatalogCode: catalogCode,
      rootOnly,
    };

    const isProductUserRequest = customerId == null;
    /* istanbul ignore next */
    if (searchFilter) {
      if (isArrayOfProductRefs(searchFilter)) {
        searchConstraints.origins = [searchFilter[0].origin];
        if (isProductUserRequest) {
          searchConstraints.productIds = searchFilter.map((pair) => pair.productId);
        }
      } else if (isArrayOfCategoryCodes(searchFilter)) {
        searchConstraints.productCategoryCodes = searchFilter;
      }
    }

    searchConstraints.applyFiltersByJwtPermissions = true;

    if (endAfter) {
      searchConstraints.endAfter = endAfter;
      searchConstraints.excludeProductsWithNextProduct =
        excludeProductsWithNextProduct !== undefined ? excludeProductsWithNextProduct : true;
    }

    return searchConstraints;
  }

  @action
  private addProducts(products: CustomerProduct[]): void {
    products.forEach((product) => this.productsMap.set(product.productIdAndOrigin, product));
    this.updateProductLocales();
  }

  @action
  private updateProductLocales = () => this.productsMap.forEach((product) => product.setLocale(this.rootStore.localeStore.currentLocale));

  getProductAnalyticsId = (product: CustomerProduct) => {
    const parts = [product.categoryCode, this.getProductLocalizedOfferingName(product)];
    return Analytics.join(...(parts.filter(Boolean) as string[]));
  };

  getProductLocalizedDescriptions = (productRef: ProductRef, isCompact = false): ProductDescriptor[] => {
    const product = productRef instanceof CustomerProduct ? productRef : this.getProduct(productRef);

    if (!product) {
      return [];
    }

    if (product.isMobileProduct && !product.isMobileFrameworkAgreement) {
      return this.getMobileProductLocalizedDescriptions(product, isCompact);
    }

    if (product.isFixedPhoneProduct) {
      return this.getFixedPhoneProductLocalizedDescriptions(product);
    }

    return this.getDefaultProductLocalizedDescriptions(product);
  };

  private getDefaultProductLocalizedDescriptions(product: CustomerProduct): ProductDescriptor[] {
    const { dealer } = this.rootStore.ssoStore;
    const descriptionArray = new ProductDescriptorArray(product.isDisabled || product.isClosed ? ' text-light' : '');
    /* istanbul ignore next */
    const { serialNumberHolder } = product.additionalAttributes || {};
    const offeringName = this.getProductLocalizedOfferingName(product);

    if (product.alias && offeringName) {
      descriptionArray.add('productName', offeringName);
    }

    this.addProductDescription(product, descriptionArray);

    if (product.productLocation) {
      descriptionArray.add('address', product.productLocation);
    }

    if (product.isClosed && serialNumberHolder?.data) {
      descriptionArray.add('serialNumber', serialNumberHolder?.data, 'SN');
    }

    if (dealer) {
      descriptionArray.add('communicationNumber', product?.communicationNumbers.join(', '), undefined, 'text-employee text-bold');
    }

    return descriptionArray;
  }

  private getMobileProductLocalizedDescriptions(product: CustomerProduct, isCompact = false): ProductDescriptor[] {
    const { ssoStore, translateStore } = this.rootStore;
    /* istanbul ignore next */
    const descriptionArray = new ProductDescriptorArray(product.isDisabled || product.isClosed ? ' text-light' : '');

    if (product.isMobileNumber && product.alias && product.formattedCommunicationNumber) {
      descriptionArray.add('communicationNumber', product.formattedCommunicationNumber);
    }

    this.addProductDescription(product, descriptionArray, translateStore.translate('common.package.name'));

    if (!isCompact) {
      const simCardsOverview = this.getLocalizedSimCardsOverview(product);
      /* istanbul ignore next */
      if (simCardsOverview) {
        descriptionArray.add('simCardInfo', simCardsOverview);
      }
    }

    if (product.isMobileNumber) {
      const showUserName = ssoStore.customerId === product.owner?.customerId;
      const name = showUserName ? product.user?.fullName : product.owner?.displayName;
      /* istanbul ignore next */
      if (name) {
        const label = translateStore.translate(showUserName ? 'common.user.name' : 'common.owner.name');
        descriptionArray.add('userOrCustomerName', name, label, 'text-light');
      }
    }

    return descriptionArray;
  }

  private getFixedPhoneProductLocalizedDescriptions(product: CustomerProduct): ProductDescriptor[] {
    /* istanbul ignore next */
    const descriptionArray = new ProductDescriptorArray(product.isDisabled || product.isClosed ? ' text-light' : '');
    const offeringName = this.getProductLocalizedOfferingName(product);
    /* istanbul ignore next */
    if (offeringName) {
      descriptionArray.add('productName', offeringName);
    }

    if (product.alias && product.formattedCommunicationNumber) {
      descriptionArray.add('communicationNumber', product.formattedCommunicationNumber);
    }

    if (product.productLocation) {
      descriptionArray.add('address', product.productLocation);
    }

    return descriptionArray;
  }

  private addProductDescription(product: CustomerProduct, descriptionArray: ProductDescriptorArray, prefix?: string) {
    if (product.productDescriptionLabel) {
      const productDescription = this.getProductLocalizedDescription(product).trim();
      /* istanbul ignore next */
      if (productDescription) {
        descriptionArray.add('productDescription', productDescription, prefix);
      }
    } else if (product.productDescription) {
      descriptionArray.add('productDescription', product.productDescription, prefix);
    }
  }

  getProductLocalizedDescription(product: CustomerProduct): string {
    const addParam = (result: any, characteristic: any) => {
      if (!characteristic.value) {
        return { ...result, [`characteristic.${characteristic.code}`]: { ...characteristic, value: '' } };
      }
      return { ...result, [`characteristic.${characteristic.code}`]: characteristic };
    };
    /* istanbul ignore next */
    const params = product.summary ? product.summary.characteristics!.reduce(addParam, {}) : {};
    return product.productDescriptionLabel
      ? this.rootStore.translateStore.translateProductcatalog(product.productDescriptionLabel, params)
      : '';
  }

  getProductLocalizedCategoryName(product: CustomerProduct): string {
    const keys = product.productCategoryCodes.map((categoryCode) => `category.${categoryCode}.name`);
    return this.rootStore.translateStore.translateProductcatalog(keys);
  }

  getProductLocalizedName = (productRef: CustomerProductSummary): string => {
    const product = productRef instanceof CustomerProductSummary ? productRef : this.getProduct(productRef);

    if (!product) {
      return '';
    }

    if (product.alias) {
      return product.alias;
    }

    return product.isMobileNumber || product.isMobilePrepaidCard || product.isFixedPhoneProduct
      ? product.formattedCommunicationNumber
      : this.getProductLocalizedOfferingName(product);
  };

  getProductLocalizedNameHtml(product: CustomerProductSummary): DangerouslySetInnerHTML {
    return { __html: this.getProductLocalizedName(product) };
  }

  getProductLocalizedOfferingName(product: CustomerProductSummary) {
    const { translateStore } = this.rootStore;
    const key = product.offeringNameLabels.find((label) => translateStore.hasProductcatalogTranslation(label));
    const translatedLabel = key && translateStore.translateProductcatalog(key);
    /* istanbul ignore next */
    return translatedLabel || product.offeringName || '';
  }

  getProductLocalizedCharacteristicName(product: CustomerProduct, characteristic: CustomerProductCharacteristic): string {
    const keys = product.productCategoryCodes.map((categoryCode) => `category.${categoryCode}.characteristic.${characteristic.code}.name`);
    return this.rootStore.translateStore.translateProductcatalog(keys);
  }

  getLocalizedCharacteristicForFirstProductCharacteristicWithTranslation(product: CustomerProduct): string | undefined {
    const characteristicValueTranslation =
      this.getLocalizedCharacteristicForFirstProductCharacteristicWithCharacteristicValueTranslation(product);

    if (characteristicValueTranslation) {
      return characteristicValueTranslation;
    }

    const { translateStore } = this.rootStore;
    const getLabelKeys = (code: CharacteristicCode) =>
      product.productCategoryCodes.map((categoryCode) => `category.${categoryCode}.characteristic.${code}.my-services.label`);
    const characteristicTranslation = product.characteristics.find((productCharacteristic) =>
      translateStore.hasProductcatalogTranslation(getLabelKeys(productCharacteristic.code))
    );
    const value = characteristicTranslation && product.getCharacteristicValue(characteristicTranslation.code)?.toLowerCase();
    return value && translateStore.translateProductcatalog(getLabelKeys(characteristicTranslation!.code), { value });
  }

  private getLocalizedCharacteristicForFirstProductCharacteristicWithCharacteristicValueTranslation(
    product: CustomerProduct
  ): string | undefined {
    const { translateStore } = this.rootStore;
    const getLabelKeys = (code: CharacteristicCode, characteristicValue: string) =>
      product.productCategoryCodes.map(
        (categoryCode) => `category.${categoryCode}.characteristic.${code}.value.${characteristicValue}.my-services.label`
      );
    const characteristicValueTranslation = product.characteristics.find((productCharacteristic) => {
      const characteristicValue = product.getCharacteristicValue(productCharacteristic.code);
      return (
        characteristicValue && translateStore.hasProductcatalogTranslation(getLabelKeys(productCharacteristic.code, characteristicValue))
      );
    });

    if (characteristicValueTranslation) {
      const characteristicValue = product.getCharacteristicValue(characteristicValueTranslation.code)!.toLowerCase();
      return translateStore.translateProductcatalog(getLabelKeys(characteristicValueTranslation.code, characteristicValue));
    }

    return undefined;
  }

  getLocalizedCharacteristicForAllProductCharacteristicWithTranslation(product: CustomerProduct): string[] {
    const result = this.getLocalizedCharacteristicForAllProductCharacteristicWithCharacteristicValueTranslation(product);

    const { translateStore } = this.rootStore;
    const getLabelKeys = (code: CharacteristicCode) =>
      product.productCategoryCodes.map((categoryCode) => `category.${categoryCode}.characteristic.${code}.my-services.label`);
    const characteristicsWithTranslation = product.characteristics.filter((productCharacteristic) =>
      translateStore.hasProductcatalogTranslation(getLabelKeys(productCharacteristic.code))
    );

    for (const characteristicWithTranslation of characteristicsWithTranslation) {
      /* istanbul ignore next */
      const value = characteristicWithTranslation && product.getCharacteristicValue(characteristicWithTranslation.code)?.toLowerCase();
      if (value) {
        result.push(translateStore.translateProductcatalog(getLabelKeys(characteristicWithTranslation!.code), { value }));
      }
    }
    return result;
  }

  private getLocalizedCharacteristicForAllProductCharacteristicWithCharacteristicValueTranslation(product: CustomerProduct): string[] {
    const { translateStore } = this.rootStore;
    const getLabelKeys = (code: CharacteristicCode, characteristicValue: string) =>
      product.productCategoryCodes.map(
        (categoryCode) => `category.${categoryCode}.characteristic.${code}.value.${characteristicValue}.my-services.label`
      );
    const characteristicsWithValueTranslation = product.characteristics.filter((productCharacteristic) => {
      const characteristicValue = product.getCharacteristicValue(productCharacteristic.code);
      return (
        characteristicValue && translateStore.hasProductcatalogTranslation(getLabelKeys(productCharacteristic.code, characteristicValue))
      );
    });

    const result = [];

    for (const characteristicWithValueTranslation of characteristicsWithValueTranslation) {
      const characteristicValue = product.getCharacteristicValue(characteristicWithValueTranslation.code)!.toLowerCase();
      /* istanbul ignore next */
      if (characteristicValue) {
        result.push(translateStore.translateProductcatalog(getLabelKeys(characteristicWithValueTranslation.code, characteristicValue)));
      }
    }
    return result;
  }

  getLocalizedCharacteristicValue(characteristic: CustomerProductCharacteristic): string {
    if (characteristic.valueLabel) {
      return this.rootStore.translateStore.translateProductcatalog(characteristic.valueLabel);
    }
    return characteristic.value;
  }

  getLocalizedCharacteristicValueHtml(characteristic: CustomerProductCharacteristic): DangerouslySetInnerHTML {
    return { __html: this.getLocalizedCharacteristicValue(characteristic) };
  }

  getLocalizedBandwidth(input: CustomerProduct | CustomerProductCharacteristic): string {
    if (input instanceof CustomerProductCharacteristic) {
      return this.getLocalizedCharacteristicValue(input);
    }
    if (input.isInternetSpeedProduct) {
      return this.getProductLocalizedName(input);
    }
    if (input.isBaseConnectionProduct) {
      return this.rootStore.translateStore.translateProductcatalog(`category.${CategoryCode.BASE_CONNECTIONS}.name`);
    }
    ErrorUtil.pushError(new Error('Trying to get bandwidth value for non-internet product'));
    return '';
  }

  getLocalizedNotice(input: CustomerProduct | CustomerProductCharacteristic): DangerouslySetInnerHTML | undefined {
    /* istanbul ignore next */
    const notice = input instanceof CustomerProductCharacteristic ? input.notice : input.summaryNotices[0];
    /* istanbul ignore next */
    const label = notice?.label;

    if (label && input instanceof CustomerProduct && input.isClosed) {
      return this.rootStore.translateStore.translateHtml(label, {
        endDate: formatDate(input.endDate!, 'DD.MM.YYYY'),
      });
    }

    return label ? this.rootStore.translateStore.translateHtml(label) : undefined;
  }

  private getLocalizedSimCardsOverview = (productRef: ProductRef): string => {
    const product = productRef instanceof CustomerProduct ? productRef : this.getProduct(productRef);

    if (!product) {
      return '';
    }

    const { isMobileGroup, children } = product;
    const labelType = isMobileGroup ? 'members' : 'sim-cards';
    const simCount = isMobileGroup ? children.length : Math.max(product.devices.length, 1);
    return this.rootStore.translateStore.translate(`my-services.mobility.${labelType}.${simCount}.label`);
  };

  getLocalizedSimCardsOverviewHtml = (productRef: ProductRef): DangerouslySetInnerHTML => ({
    __html: this.getLocalizedSimCardsOverview(productRef),
  });

  /* istanbul ignore next */
  getNoticeType(input: CustomerProduct | CustomerProductCharacteristic): INoticeProps['type'] | undefined {
    const notice = input instanceof CustomerProductCharacteristic ? input.notice : input.summaryNotices[0];
    return notice?.type === 'warning' ? 'error' : notice?.type;
  }

  @action
  clear = (...productRefs: Array<ProductRef | string>): void => {
    if (!productRefs.length) {
      this.productsMap.clear();
      return;
    }
    for (const productRef of productRefs) {
      /* istanbul ignore next */
      const productIdAndOrigin = typeof productRef === 'string' ? productRef : productRef.productIdAndOrigin;
      this.productsMap.delete(productIdAndOrigin);
    }
  };

  private handleProductsMapChange = (change: IMapDidChange<string, CustomerProduct>) => {
    /* istanbul ignore else */
    if (change.type === 'add') {
      this.waitForOrdersToCompleteAndDisplayMessage(change.newValue);
    } else if (change.type === 'delete') {
      this.stopWaitingForOrdersToComplete(change.oldValue);
      change.oldValue.terminate();
    }
  };

  private waitForOrdersToCompleteAndDisplayMessage(product: CustomerProduct) {
    const { productIdAndOrigin, orderStatuses } = product;
    const isOrdersFinishedAndSynced = () => orderStatuses.isAllOrdersFinished && !product.isDetailsLoading;
    this.productReactionDisposers.set(
      productIdAndOrigin,
      reaction(isOrdersFinishedAndSynced, (yes) => yes && this.addOrdersFinishedMessageForProduct(product))
    );
  }

  private addOrdersFinishedMessageForProduct(product: CustomerProduct) {
    const { productIdAndOrigin, orderStatuses } = product;
    const [orderStatus] = orderStatuses.finishedOrderStatuses;
    const isSingular = orderStatuses.finishedOrderStatuses.length === 1;
    const messageTranslationKey = orderStatus.getLabelKey(isSingular);
    this.rootStore.messageStore.addMessage(
      productIdAndOrigin,
      new Message({
        key: MessageKey.PRODUCT_ORDERS_FINISHED,
        options: {
          type: orderStatus.isFulfilled ? 'success' : 'error',
          closable: true,
        },
        render: this.renderOrdersFinishedMessage.bind(this, product, messageTranslationKey),
      })
    );
  }

  private renderOrdersFinishedMessage(product: CustomerProduct, messageTranslationKey: string) {
    const { translate } = this.rootStore.translateStore;
    const { isRoamingEnabled } = product;
    const messageParams = {
      succeededRoamingState: translate('my-services.finalized-order.ROAMING.succeeded-' + (isRoamingEnabled ? 'enable' : 'disable')),
      failedRoamingState: translate('my-services.finalized-order.ROAMING.failed-' + (isRoamingEnabled ? 'disable' : 'enable')),
    };
    const renderMessage = () => React.createElement(React.Fragment, null, translate(messageTranslationKey, messageParams));
    return React.createElement(Observer, null, renderMessage);
  }

  private stopWaitingForOrdersToComplete(product: CustomerProduct) {
    const { productIdAndOrigin } = product;
    const disposer = this.productReactionDisposers.get(productIdAndOrigin);
    /* istanbul ignore else */
    if (disposer) {
      disposer();
    }
    this.productReactionDisposers.delete(productIdAndOrigin);
  }
}
