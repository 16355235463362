import { action, computed, observable, makeObservable } from 'mobx';
import AppError, { AppErrorValue } from './AppError';
import { ErrorUtil } from '../../util/ErrorUtil';
import { Analytics } from '../../util/Analytics';

type Errors = Map<string, AppError>;

export default class ErrorStore {
  static level = {
    VALIDATION_ERROR: 'validationError',
    TECHNICAL_ERROR: 'technicalError',
    BUSINESS_ERROR: 'businessError',
  };

  @observable errors: Errors = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  public setError(level: string, value: AppError): Errors {
    if (process.env.NODE_ENV === 'development') {
      this.logError(value);
    }

    return this.errors.set(level, value);
  }

  @action
  public clearError(level: string): boolean {
    return this.errors.delete(level);
  }

  @action
  public reset() {
    this.errors.clear();
  }

  public getError(level: string): AppError | undefined {
    return this.errors.get(level);
  }

  @computed
  get generalTechnicalError(): AppErrorValue {
    return this.errors.get(ErrorStore.level.TECHNICAL_ERROR) as AppErrorValue;
  }

  public setGeneralTechnicalError(originalError: AppErrorValue['originalError'], category?: string): Errors {
    if (category) {
      Analytics.push({
        event: Analytics.defaultEvent,
        page: category,
        group: 'ERROR',
        id: 'Open error modal',
      });
    }
    if (originalError) {
      ErrorUtil.pushError(originalError);
    }

    return this.setError(ErrorStore.level.TECHNICAL_ERROR, {
      message: 'error.backend.general',
      type: 'error',
      originalError,
      category,
    });
  }

  private logError(value: AppError): void {
    if (isAppErrorValue(value)) {
      console.error(value.originalError || value.message);
    } else {
      for (const key in value) {
        /* istanbul ignore else */
        if (value.hasOwnProperty(key)) {
          this.logError(value[key]);
        }
      }
    }
  }
}

export function isAppErrorValue(value: AppError): value is AppErrorValue {
  return (value as AppErrorValue).message !== undefined && (value as AppErrorValue).type !== undefined;
}
