import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import './shared/util/publicFolder'; // must be imported before setupComponentLibrary
import './shared/util/setupComponentLibrary'; // must be imported before App
import App from './App';
import * as mobx from 'mobx';
import { setGrafanaRum } from './shared/util/GrafanaRum';
import { CoreConfig } from './shared/util/CoreConfig';
import { ErrorUtil } from './shared/util/ErrorUtil';
import { Analytics } from './shared/util/Analytics';
import { API } from './shared/util/apiUtil';
import { TeliaOtelWebConfig } from './shared/model/config/TeliaOtelWebConfig';

window.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
  error = error || new Error(event instanceof Event ? event.type : event);
  ErrorUtil.pushError(error);
  return true;
};

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  event.preventDefault();
  const error = event.reason instanceof Error ? event.reason : new Error(event.reason);
  ErrorUtil.pushError(error);
});

const doNotLoadPageFromCache = (event: PageTransitionEvent) => {
  if (event.persisted) {
    window.location.reload();
  }
};
window.addEventListener('pageshow', doNotLoadPageFromCache);

CoreConfig.init({
  environment: window.INITIAL_CONFIG.environment,
  teliaOtelWebConfig: window.INITIAL_CONFIG.teliaOtelWebConfig as TeliaOtelWebConfig,
  context: navigator.userAgent.toLowerCase().includes('webview') ? 'webview' : 'browser',
});
CoreConfig.currentLocale = window.INITIAL_CONFIG.locale;
API.defaults.baseURL = '/';
Analytics.init(CoreConfig.environment);

mobx.configure({ enforceActions: 'observed', useProxies: 'never' });

if (CoreConfig.environment !== 'local') {
  setGrafanaRum();
}

ReactDOM.render(<App />, document.getElementById('container'));
// eslint-disable-next-line no-console
console.info('myse-frontend domain');
