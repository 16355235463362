/* istanbul ignore file */

import { action, comparer, computed, observable, reaction, makeObservable } from 'mobx';
import { ApiResponse } from '../model/api/ApiResponse';
import { Client, StompConfig, StompHeaders, StompSubscription } from '@stomp/stompjs';
import { validateJsonResponse } from '../util/apiUtil';
import { ErrorUtil } from '../util/ErrorUtil';

export interface PubSubConnectionStoreContext {
  ssoStore: {
    customerId: number | undefined;
  };
  mandateCheckerStore: {
    serviceMandateLimitedAccountIds: string[] | undefined;
  };
  featureStore: {
    isEnabled: (feature: string) => boolean;
  };
}

type MessageCallback<T> = (payload: ApiResponse<T>) => void;

interface WebSocketConnection {
  subscribe: (destination: string, callback: MessageCallback<any>, headers?: StompHeaders) => StompSubscription;
  unsubscribe: Client['unsubscribe'];
  close: Client['deactivate'];
}

interface CustomerDetails {
  accountIds: string | undefined;
  customerId: number | undefined;
}

type UnsubscribeFn = () => void;

interface Subscription {
  topic: string;
  callback: MessageCallback<any>;
  unsubscribe: UnsubscribeFn;
}

export abstract class PubSubConnectionStore {
  protected abstract connectionUrl: string;

  @observable.ref private webSocketConnection: WebSocketConnection | undefined;
  private subscriptions: { [id: string]: Subscription } = {};
  private getCustomerDetails = (): CustomerDetails => ({
    accountIds: this.accountIds,
    customerId: this.context.ssoStore.customerId,
  });
  /* istanbul ignore next */
  private disposeReaction = () => {};

  constructor(private context: PubSubConnectionStoreContext) {
    makeObservable(this);
  }

  init() {
    /* istanbul ignore next 3 */
    if (!this.context.featureStore.isEnabled('ENABLE_WEBSOCKET')) {
      return;
    }

    this.disposeReaction = reaction(this.getCustomerDetails, this.togglePersonalWebSocketConnection, {
      fireImmediately: true,
      equals: comparer.structural,
    });
  }

  @computed
  get isConnected() {
    return !!this.webSocketConnection;
  }

  async terminate() {
    this.subscriptions = {};
    this.disposeReaction();
    await this.closeWebSocketConnection();
  }

  subscribe = <T>(topic: string, callback: MessageCallback<T>): UnsubscribeFn => {
    /* istanbul ignore next 3 */
    if (!this.context.featureStore.isEnabled('ENABLE_WEBSOCKET')) {
      return () => {};
    }

    if (!this.webSocketConnection) {
      throw new Error(`Cannot subscribe to topic “${topic}” because WebSocket connection has not been opened!`);
    }

    const { unsubscribe } = this.webSocketConnection.subscribe(`/topic/${topic}`, callback);
    const id = (Date.now() + Math.random()).toString(36);
    this.subscriptions[id] = { topic, callback, unsubscribe };

    return () => {
      this.subscriptions[id].unsubscribe();
      delete this.subscriptions[id];
    };
  };

  private resubscribeAll(webSocketConnection: WebSocketConnection) {
    for (const [id, { topic, callback }] of Object.entries(this.subscriptions)) {
      this.subscriptions[id].unsubscribe = webSocketConnection.subscribe(`/topic/${topic}`, callback).unsubscribe;
    }
  }

  @computed
  private get accountIds(): string | undefined {
    const accountIds = this.context.mandateCheckerStore.serviceMandateLimitedAccountIds;

    /* istanbul ignore next 3 */
    if (!accountIds) {
      return undefined;
    }

    return accountIds.toString();
  }

  @action
  private setWebSocketConnection(webSocketConnection: WebSocketConnection | undefined) {
    this.webSocketConnection = webSocketConnection;
  }

  private togglePersonalWebSocketConnection = async ({ customerId, accountIds }: CustomerDetails) => {
    await this.closeWebSocketConnection();

    if (customerId) {
      const webSocketConnection = await this.openWebSocketConnection({ customerId, accountIds });
      this.resubscribeAll(webSocketConnection);
    }
  };

  private async openWebSocketConnection({ accountIds }: CustomerDetails): Promise<WebSocketConnection> {
    /* istanbul ignore next */
    const headers = accountIds ? { accountIds } : undefined;
    const webSocketConnection = await this.openWSConnection(this.connectionUrl, headers);

    this.setWebSocketConnection(webSocketConnection);
    return webSocketConnection;
  }

  private async closeWebSocketConnection(): Promise<void> {
    if (!this.webSocketConnection) {
      return;
    }

    await this.webSocketConnection.close();
    this.setWebSocketConnection(undefined);
  }

  private async openWSConnection(brokerURL: string, connectHeaders?: StompHeaders): Promise<WebSocketConnection> {
    const client = new Client({
      brokerURL,
      connectHeaders,
      onStompError: this.onStompError,
    });
    const connection: WebSocketConnection = {
      unsubscribe: client.unsubscribe,
      close: client.deactivate,
      subscribe: (destination, callback, headers) =>
        client.subscribe(destination, (message) => callback(validateJsonResponse(message.body, headers)), headers),
    };
    const connectionPromise = new Promise<WebSocketConnection>((fulfill) => (client.onConnect = () => fulfill(connection)));

    client.activate();

    return connectionPromise;
  }

  private onStompError: StompConfig['onStompError'] = ({ headers, body }) => {
    /* istanbul ignore next */
    const errorMessage = `Stomp error: ${headers.message}` + (body ? `\n${body}` : '');
    ErrorUtil.pushError(new Error(errorMessage));
  };
}
