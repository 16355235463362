import { ProductRef } from '../components/ProductRef';
import { ProductState } from './ProductState';
import { ProductSubstate } from './ProductSubState';
import { ProductPrice } from '../components/ProductPrice';
import { CatalogCode } from './CatalogCode';
import { CategoryCode } from './CategoryCode';
import { Origin } from './Origin';
import { Customer } from './Customer';
import { NoticeResource } from './NoticeResource';
import { formatCommunicationNumber } from '../util/formatCommunicationNumber';
import { nowIsAfter } from '../util/dateUtil';
import { CustomerProductResource, CustomerProductsAdditionalAttributesResource } from './CustomerProductResource';
import {
  CustomerProductChildCategoryCode,
  customerProductChildCategoryCodes,
  CustomerProductRootCategoryCode,
  customerProductRootCategoryCodes,
} from './CustomerProductRootCategoryCode';
import { ProductUserResource } from './ProductUserResource';

export class CustomerProductSummary extends ProductRef {
  constructor(readonly summary: CustomerProductResource) {
    super({ productId: summary.productId, origin: summary.origin });
  }

  get productCategoryCodes(): string[] {
    return this.summary.productCategoryCodes || [];
  }

  get communicationNumbers(): string[] {
    return this.summary.communicationNumbers || [];
  }

  get productIcon(): string | undefined {
    return this.summary.productIcon;
  }

  get productLocation(): string | undefined {
    return this.summary.productLocation;
  }

  get alias(): string {
    return this.summary.alias || '';
  }

  get offeringName(): string | undefined {
    return this.summary.productOfferingName || this.summary.offeringName;
  }

  get offeringNameLabels(): { 0: string } & string[] {
    return (
      (this.summary.offeringNameLabel && [this.summary.offeringNameLabel]) || [
        'offering.' + this.summary.productOfferingCode + '.my-services.name',
        'offering.' + this.summary.productOfferingCode + '.name',
      ]
    );
  }

  get productDescription(): string | null {
    return this.summary.productDescription || null;
  }

  get productDescriptionLabel(): string | null {
    return this.summary.productDescriptionLabel || null;
  }

  get productName(): string | undefined {
    return this.alias ? this.alias : this.summary.offeringName;
  }

  get offeringNameLabel(): string {
    return this.offeringNameLabels[0];
  }

  get isMobileNumber(): boolean {
    return this.summary.template === 'MOBILE_PACKAGE' || this.isLegacyMobileNumber;
  }

  get productState(): ProductState | undefined {
    return this.summary.productState;
  }

  get summaryNotices(): NoticeResource[] {
    return this.summary.productNotice ? [this.summary.productNotice] : [];
  }

  get productSubstate(): ProductSubstate | null {
    return this.summary.productSubstate || null;
  }

  get summaryPrices(): ProductPrice[] {
    return (this.summary.pricesHolder?.data || []).map((json) => new ProductPrice(json));
  }

  get isMobileProduct(): boolean {
    return this.isMobileNumber || this.isMobileGroup || this.isMobilePrepaidCard || this.isMobileFrameworkAgreement;
  }

  get communicationNumber(): string | undefined {
    return this.communicationNumbers[0];
  }

  get productImageSource() {
    return this.summary.productImageSource;
  }

  get owner(): Customer | undefined {
    return this.summary.owner;
  }

  get user(): ProductUserResource | undefined {
    return this.summary.user;
  }

  get endDate(): string | null {
    return this.summary.endDate || null;
  }

  get productSpecId(): number | undefined {
    return this.summary.productSpecId;
  }

  get productNumber(): string | undefined {
    return this.summary.productNumber || undefined;
  }

  get accountId(): number | undefined {
    return this.summary.account?.accountId || this.summary.accountId;
  }

  get primaryChild(): boolean {
    return this.summary.primaryChild || false;
  }

  get orderBy(): number | undefined {
    return this.summary.orderBy;
  }

  get additionalAttributes(): CustomerProductsAdditionalAttributesResource {
    return this.summary.additionalAttributes || {};
  }

  get productOfferingCode(): string {
    return this.summary.productOfferingCode;
  }

  /* istanbul ignore next */
  get catalogCode(): CatalogCode | null {
    // todo: MYSE-1992 - catalog code is required for getting offers
    if (!this.summary.catalogCode) {
      if (this.isMobileProduct) {
        return CatalogCode.MOBIILI_PAKETID;
      } else if (this.productCategoryCodes.includes('TV_TEENUSED')) {
        return CatalogCode.KODUTEENUSED_ERA_WEB;
      }
    }

    return this.summary.catalogCode;
  }

  /**
   * @deprecated Should not be necessary. MYSE-3530 will fix missing MOBILE_PACKAGE category in compact product summary object
   * @todo Remove after MYSE-3530 is done and propagated
   */
  private get isLegacyMobileNumber() {
    return !this.productCategoryCodes.length && this.hasOrigin(Origin.TBCIS);
  }

  hasOrigin(...origins: Origin[]): boolean {
    return origins.some((origin) => this.origin === origin);
  }

  get isIncludedInOffer(): boolean {
    return this.isInCategory(CategoryCode.INCLUDED_IN_OFFER);
  }

  get isMobileGroup(): boolean {
    return this.isInCategory(CategoryCode.MOBILE_GROUPS);
  }

  get isNewsStand(): boolean {
    return this.isInCategory(CategoryCode.NEWSSTAND);
  }

  get isDeviceInsurance(): boolean {
    return this.isInCategory(CategoryCode.DEVICE_INSURANCE);
  }

  get isMobilePrepaidCard(): boolean {
    return this.isInCategory(CategoryCode.MOBILE_PREPAID_CARD);
  }

  get isMobileFrameworkAgreement(): boolean {
    return this.isInCategory(CategoryCode.MOBILE_FRAMEWORK_AGREEMENTS);
  }

  get isFixedPhoneProduct(): boolean {
    return this.hasCategory(CategoryCode.PHONE_SERVICES);
  }

  get isDevice(): boolean {
    return this.isInCategory(CategoryCode.DEVICES);
  }

  get isMobileMicroPackage(): boolean {
    return this.isInCategory(CategoryCode.MOBILE_MICROPACKAGES);
  }

  get isInternetSpeedProduct(): boolean {
    return this.isInCategory(CategoryCode.INTERNET_SPEEDS);
  }

  get isBaseConnectionProduct(): boolean {
    return this.isInCategory(CategoryCode.BASE_CONNECTIONS);
  }

  get isTvProduct(): boolean {
    return this.isInCategory(CategoryCode.TV_SERVICES);
  }

  get isSmartWifi(): boolean {
    return this.isInCategory(CategoryCode.SMARTWIFI_TEENUSED);
  }

  get isF4gInternet(): boolean {
    return this.hasOrigin(Origin.ORDERAPI) && this.isInCategory(CategoryCode.INTERNET_SERVICES);
  }

  get supportsDeleteProductRequestForm(): boolean {
    return (
      this.hasOrigin(Origin.ARGOS) &&
      !this.isInCategory(CategoryCode.HIRE_PURCHASE, CategoryCode.TV_SERVICES, CategoryCode.PHONE_SERVICES, CategoryCode.INTERNET_SERVICES)
    );
  }

  get formattedCommunicationNumber(): string {
    return formatCommunicationNumber(this.communicationNumbers);
  }

  get categoryCode(): CustomerProductRootCategoryCode | CustomerProductChildCategoryCode | undefined {
    return (
      customerProductRootCategoryCodes.find((productCategoryCode) => this.productCategoryCodes.includes(productCategoryCode)) ||
      customerProductChildCategoryCodes.find((productCategoryCode) => this.productCategoryCodes.includes(productCategoryCode))
    );
  }

  hasCategory(...categoryCodes: CategoryCode[]): boolean {
    return categoryCodes.some((categoryCode) => this.categoryCode === categoryCode);
  }

  isInCategory(...categoryCodes: CategoryCode[]): boolean {
    return categoryCodes.some((categoryCode) => this.productCategoryCodes.includes(categoryCode));
  }

  get physicalAddress(): { street?: string; city?: string } {
    if (!this.productLocation) {
      return {};
    }

    const commaIndex = this.productLocation.indexOf(',');

    return {
      street: this.productLocation.substring(0, commaIndex).trim(),
      city: this.productLocation.substring(commaIndex + 1).trim(),
    };
  }

  get isActive(): boolean {
    return this.isInState(ProductState.ACTIVE);
  }

  get isDisabled(): boolean {
    return (
      this.isInState(ProductState.PENDING_ACTIVE) ||
      (this.isInState(ProductState.PENDING_TERMINATE) && this.categoryCode === CategoryCode.DEVICES) ||
      this.isClosed
    );
  }

  get isSuspended(): boolean {
    return this.isInState(ProductState.SUSPENDED);
  }

  get isClosed(): boolean {
    return nowIsAfter(this.endDate);
  }

  get isTerminated(): boolean {
    return this.isInState(ProductState.TERMINATED, ProductState.PENDING_TERMINATE);
  }

  get hasLimitedActions(): boolean {
    return this.isInState(ProductState.ACTIVE) && this.productSubstate === ProductSubstate.PENDING_TERMINATE;
  }

  protected isInState(...states: ProductState[]): boolean {
    return this.productState ? states.includes(this.productState) : false;
  }
}
