export enum CategoryCode {
  DOWNSELL_ALLOWED = 'ALLAMYYK_LUBATUD',
  BASE_CONNECTIONS = 'ALUSYHENDUSED',
  SMARTWIFI_TEENUSED = 'SMARTWIFI_TEENUSED',
  SMARTWIFI_TEENUSED_SUUR = 'SMARTWIFI_TEENUSED_SUUR',
  SMARTWIFI_TEENUSED_VAIKE = 'SMARTWIFI_TEENUSED_VAIKE',
  TV_POPULAR = 'TV_POPULAARNE',
  TV_GENRES = 'TV_ZANRID',
  TV_EXTRA_SERVICES = 'TV_LISATEENUSED',
  TV_VOD_LIMITS = 'TV_VOD_LIMIIDID',
  TV_SCREENS = 'TV_EKRAANID',
  TV_RECORDING = 'TV_SALVESTAMINE',
  TV_DEVICES = 'TV_SEADMED',
  TV_THEME_PACKAGES = 'TV_TEEMAPAKETID',
  TV_SERVICES = 'TV_TEENUSED',
  TV_FIX = 'TV_FIX',
  TV_SERVICES_EST = 'TV_TEENUSED_EST',
  TV_SERVICES_RUS = 'TV_TEENUSED_RUS',
  TV_BASE_PACKAGES = 'TV_POHIPAKETID',
  TV_EXTRA_CHANNELS = 'TV_NOPPEKANALID',
  TV_TELIA1_DISCOUNTS = 'TELIA1_TV_SOODUSTUSED',
  INCLUDED_IN_OFFER = 'SISALDUB_PAKKUMISES',
  DEVICES = 'SEADMED',
  FIX_SOLUTIONS = 'FIX_LAHENDUSED',
  BIDDING = 'HINNAPAKKUMISED',
  BIDDING_TOT = 'HINNAPAKKUMINE_TOT',
  BIDDING_MARKET = 'HINNAPAKKUMINE_KOGUTURU',
  INTERNET_EXTRA_SERVICES = 'INTERNETI_LISATEENUSED',
  INTERNET_DEVICES = 'INTERNETI_SEADMED',
  INTERNET_DEVICES_TECHNOLOGIES = 'INTERNETI_SEADMETE_TEHNOLOOGIAD',
  INTERNET_DEVICES_4G = 'F4G_INTERNETI_SEADMED',
  INTERNET_DEVICES_5G = 'F5G_INTERNETI_SEADMED',
  INTERNET_DEVICES_FIX = 'FIX_INTERNETI_SEADMED',
  INTERNET_SERVICES = 'INTERNETI_TEENUSED',
  INTERNET_SERVICES_4G = '4G_INTERNETI_TEENUSED',
  INTERNET_SPEEDS = 'INTERNETI_KIIRUSED',
  IP_ADDRESSES = 'IP_AADRESSID',
  PHONE_SERVICES = 'TELEFONI_TEENUSED',
  DEVICE_INSURANCE = 'SEADMEKINDLUSTUS',
  NEWSSTAND = 'LEHERIIUL',
  HIRE_PURCHASE = 'JARELMAKS',
  SAFE = 'SAFE',
  SPOTIFY = 'SPOTIFY',
  MINUTV = 'MINUTV',
  OTHER_SERVICES = 'MUUD_TEENUSED',
  RETURN_OFFERS = 'TAGASTUS_PAKKUMISED',
  RETURN_TO_SHOP_OFFERS = 'TAGASTAMINE_ESINDUSSE_PAKKUMISED',
  RETURN_TO_PARCEL_MACHINE_OFFERS = 'TAGASTAMINE_PAKIAUTOMAADIGA_PAKKUMISED',
  RETURN_WITH_TECHNICIAN_OFFERS = 'TAGASTAMINE_TEHNIKUGA_PAKKUMISED',
  SIM_CARDS = 'SIM_KAARDID',
  MOBILE_GROUPS = 'MOBIILI_GRUPID',
  MOBILE_PACKAGES = 'MOBIILI_PAKETID',
  MOBILE_PREPAID_CARD = 'MOBIILI_KONEKAARDID',
  MOBILE_LIMITS = 'MOBIILI_LIMIIDID',
  MOBILE_GROUP_LIMIT = 'MOBIILI_GRUPI_LIMIIT',
  MOBILE_NOTIFICATIONS = 'MOBIILI_TEAVITUSED',
  MOBILE_EXTRA = 'MOBIILI_LISAMAHT',
  MOBILE_MONTHLY_EXTRA = 'MOBIILI_KUUTASULINE_LISAMAHT',
  MOBILE_EXTRA_CALLS = 'MOBIILI_KUUTASULINE_LISAKONEMAHT',
  MOBILE_AUTO_EXTRA = 'MOBIILI_AUTOMAATNE_LISAMAHT',
  MOBILE_OTHER_SERVICES = 'MOBIILI_MUUD_LISATEENUSED',
  MOBILE_FORWARDING = 'MOBIILI_SUUNAMISED',
  BUSINESS_TV = 'ARI_TV',
  INFO_TV = 'INFO_TV',
  MS_365_PACKAGES = 'MS_365_PAKETID',
  MS_365_ACCOUNTS = 'MS_365_KONTOD',
  MS_365 = 'MS_365',
  ARGOS_MS_365 = 'ARGOS_MS_365',
  NEW_ORDER_FLOW_CONTACT_DATA_REQUIRED = 'LIITUMISEL_KONTAKTANDMED_VAJALIKUD',
  INTERNAL_ROUTERS = 'SISERUUTERID',
  TELIA_PLAY = 'TELIA_PLAY',
  TEHNIKU_TARNE = 'TEHNIKU_TARNE',
  SMART_CONNECT = 'SMART_CONNECT_TEENUSED',
  MOBILE_MICROPACKAGES = 'MOBIILI_MIKROPAKETID',
  SMART_WIFI_PACKAGES_WITH_ROUTER = 'SMARTWIFI_TEENUSED_RUUTERIGA',
  SMART_WIFI_PACKAGES_WITHOUT_ROUTER = 'SMARTWIFI_TEENUSED_RUUTERITA',
  SMART_WIFI_PACKAGES_WITHOUT_ROUTER_AND_WITHOUT_ROUTER_4G = 'SMARTWIFI_TEENUSED_RUUTERITA_JA_RUUTERITA_4G',
  SMART_WIFI_6_PACKAGES_WITHOUT_ROUTER = 'SMARTWIFI6_TEENUSED_RUUTERITA',
  MOBILE_FRAMEWORK_AGREEMENTS = 'MOBIILI_RAAMTEENUSED',
  SEADMEKINDLUSTUS = 'SEADMEKINDLUSTUS',
  PRIVATE_BILL_SERVICES = 'ERA_ARVE_TEENUSED',
  ACTIVATABLE_SERVICES = 'AKTIVEERITAVAD_TEENUSED',
  EKODU_KOMPONENT = 'EKODU_KOMPONENT',
  PORTS = 'PORDID',
  PRIVATE_BILL_PAYMENT = 'ERA_ARVE_MAKSED',
  STATIC_IP = 'STAATILINE_IP',
  FINANCE_SERVICES = 'FINANTS_TEENUSED',
  HOSTING_SERVICES = 'HOSTING_TEENUSED',
  IT_SERVICES = 'IT_TEENUSED',
  HOIA_RUUTERID = 'HOIA_RUUTERID',
  HOIA_DIGIBOKSID = 'HOIA_DIGIBOKSID',
}
