import { action, observable, makeObservable } from 'mobx';

export default class NotFoundStore {
  @observable searchFieldValue = '';

  constructor() {
    makeObservable(this);
  }

  @action
  setSearchFieldValue(searchFieldValue: string): void {
    this.searchFieldValue = searchFieldValue;
  }
}
