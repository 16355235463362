export enum ExternalLink {
  HELP = 'HELP',
  SHOP = 'SHOP',
  TELIAWEB = 'TELIAWEB',
  WEB3_BILLING = 'WEB3_BILLING',
  WEB3_INTERNET = 'WEB3_INTERNET',
  TELIA_PRIVATE_HOME = 'TELIA_PRIVATE_HOME',
  IT_PORTAL = 'IT_PORTAL',
  GDPR = 'GDPR',
  ARGOS = 'ARGOS',
}

export type ExternalLinks = { [key in ExternalLink]: string };
