import React, { useContext } from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { observer } from 'mobx-react';
import { CommonStoreContext } from './CommonStoreContext';

interface HeadContext {
  localeStore: {
    currentLocale: 'et' | 'ru' | 'en';
  };
  translateStore: {
    translate: (key: string) => string;
  };
}
export type HeadProps = HelmetProps;

export const Head = observer((props: HeadProps) => {
  const { localeStore, translateStore }: HeadContext = useContext(CommonStoreContext);

  const helmetProps: HelmetProps = {
    ...props,
    base: { href: '/' },
    htmlAttributes: {
      ...props.htmlAttributes,
      lang: localeStore.currentLocale,
    } as HelmetProps['htmlAttributes'],
    title: props.title && translateStore.translate(props.title),
    meta: props.meta || [],
  };

  return (
    <Helmet
      titleTemplate={translateStore.translate('head.title.template')}
      defaultTitle={translateStore.translate('head.title.default')}
      {...helmetProps}
    />
  );
});
