import { ProductDescriptor, ProductDescriptorType } from './ProductDescriptor';

export class ProductDescriptorArray extends Array<ProductDescriptor> {
  constructor(readonly className?: string) {
    super();
  }

  add(type: ProductDescriptorType, value?: string, label?: string, className?: string) {
    const classNames = [this.className, className].filter(Boolean);
    const htmlTextArray: string[] = [];

    if (value) {
      htmlTextArray.push(`${value}`);
    }

    this.push({
      type,
      htmlText: `<span class="${classNames.join(' ')}">${htmlTextArray.join(': ')}</span>`,
    });
  }
}
