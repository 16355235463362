/* istanbul ignore next */
import { isApiResponseWithError } from '../util/apiUtil';
import { AllowedActionResource } from '../model/action/AllowedAction';
import { ProductAllowedAction } from '../model/action/ProductAllowedAction';
import { ApiResponse } from '../model/api/ApiResponse';
import { ImageSource } from '../model/ImageSource';
import { NoticeResource } from '../model/NoticeResource';
import { ProductDetailsPriceResource, ProductPrice } from './ProductPrice';
import { SimEvent } from '../model/SimEvent';

export interface ProductDeviceModelResource {
  displayName: string | null;
  displayNameLabel: string | null;
  imeiHolder: ApiResponse<string | null | undefined>;
}

export interface ProductDeviceSimResource {
  serialNumber: string | null;
  puk1: string | null;
  puk2: string | null;
  pin1: string | null;
  pin2: string | null;
  esimActivationQrCodeImageLink: string | null;
  esimActivationCode: string | null;
  events: ApiResponse<SimEvent[]>;
}

export interface ProductDeviceResource {
  image: string;
  imageSource: ImageSource | 'css';
  typeLabel: string | null;
  disabled: boolean;
  suspended: boolean;
  alias: string | null;
  productGlobalId: string | null;
  actions: ApiResponse<AllowedActionResource[]>;
  notices: ApiResponse<NoticeResource[]>;
  prices: ApiResponse<ProductDetailsPriceResource[]>;
  model: ApiResponse<ProductDeviceModelResource>;
  sim: ApiResponse<ProductDeviceSimResource>;
  serialNumberHolder: ApiResponse<string | null | undefined>;
}

export class ProductDevice {
  image: string;
  imageSource: ImageSource | 'css';
  typeLabel: string;
  isModelErrored: boolean;
  isDisabled: boolean;
  isSuspended: boolean;
  maxSpeed: string | null;
  alias: string | null;
  productIdAndOrigin: string | null;
  displayName: string | null | undefined;
  displayNameLabel: string | null | undefined;
  isSimErrored: boolean;
  isSimEventsErrored: boolean;
  simSerialNumber: string | null | undefined;
  simPuk1: string | null | undefined;
  simPuk2: string | null | undefined;
  simPin1: string | null | undefined;
  simPin2: string | null | undefined;
  esimActivationQrCodeImageLink: string | null | undefined;
  esimActivationCode: string | null | undefined;
  simEvents: SimEvent[] | undefined;
  isActionsErrored: boolean;
  actions: ProductAllowedAction[];
  price: ProductPrice | undefined;
  noticeLabel: string | undefined;
  noticeType?: 'warning' | 'success' | 'employee';
  imeiHolder?: ApiResponse<string | null | undefined>;
  serialNumberHolder: ApiResponse<string | null | undefined>;

  constructor(resource: ProductDeviceResource) {
    this.image = resource.image;
    this.imageSource = resource.imageSource;
    this.typeLabel = resource.typeLabel || 'my-services.common.device.name';
    this.isDisabled = resource.disabled;
    this.isSuspended = resource.suspended;
    this.alias = resource.alias;
    this.productIdAndOrigin = resource.productGlobalId;
    this.isModelErrored = isApiResponseWithError(resource.model);
    this.displayName = resource.model.data?.displayName;
    this.displayNameLabel = resource.model.data?.displayNameLabel;
    this.isSimErrored = isApiResponseWithError(resource.sim);
    const sim = resource.sim.data;
    this.simSerialNumber = sim?.serialNumber;
    this.simPuk1 = sim?.puk1;
    this.simPuk2 = sim?.puk2;
    this.simPin1 = sim?.pin1;
    this.simPin2 = sim?.pin2;
    this.esimActivationQrCodeImageLink = sim?.esimActivationQrCodeImageLink;
    this.esimActivationCode = sim?.esimActivationCode;
    this.simEvents = sim?.events?.data;
    this.isSimEventsErrored = isApiResponseWithError(sim?.events);
    this.isActionsErrored = isApiResponseWithError(resource.actions);
    this.actions = (resource.actions.data || []).map((action) => new ProductAllowedAction(action));
    this.imeiHolder = resource.model.data?.imeiHolder;
    this.serialNumberHolder = resource.serialNumberHolder;

    const recurringPriceResource = resource.prices.data?.find(({ priceType }) => priceType === 'RECURRING_FEE');
    this.price = recurringPriceResource && new ProductPrice(recurringPriceResource);

    const [noticeResource] = resource.notices.data || [];
    this.noticeLabel = noticeResource?.label;
    this.noticeType = noticeResource?.type;
  }
}
