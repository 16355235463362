import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AuthRedirectContext } from '../../model/AuthRedirectContext';
import { AuthRedirectStore } from './AuthRedirectStore';
import { CommonStoreContext } from '../CommonStoreContext';

export interface AuthRedirectProps {
  children: React.ReactNode;
}
/**
 * Detects `auth-goto` query parameter in location URL and invokes SSO modal for user sign-in and redirection.
 */
export const AuthRedirect: React.FC<AuthRedirectProps> = observer((props) => {
  const context: AuthRedirectContext = useContext(CommonStoreContext);
  const [store] = useState(() => new AuthRedirectStore(context));

  useEffect(() => {
    store.init();
    return () => store.terminate();
  }, []);

  return store.isAuthRedirectInProgress ? null : <>{props.children}</>;
});
