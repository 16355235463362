/* istanbul ignore next */
export const atobUTF8 = (inputString: string, keepBOM: boolean) => {
  const originalAtob = atob;

  const replacer = (encoded: string) => {
    const log = Math.log;
    const LN2 = Math.LN2;
    const clz32 = Math.clz32 || ((x) => (31 - log(x >>> 0) / LN2) | 0);
    const fromCharCode = String.fromCharCode;
    const stringLen = encoded.length;
    let codePoint = encoded.charCodeAt(0) << 24;
    const leadingOnes = clz32(~codePoint);
    let endPos = 0;
    let result = '';

    if (leadingOnes < 5 && stringLen >= leadingOnes) {
      codePoint = (codePoint << leadingOnes) >>> (24 + leadingOnes);
      for (endPos = 1; endPos < leadingOnes; ++endPos) {
        codePoint = (codePoint << 6) | (encoded.charCodeAt(endPos) & 0x3f);
      }
      if (codePoint <= 0xffff) {
        result += fromCharCode(codePoint);
      } else if (codePoint <= 0x10ffff) {
        codePoint -= 0x10000;
        result += fromCharCode((codePoint >> 10) + 0xd800, (codePoint & 0x3ff) + 0xdc00);
      } else {
        endPos = 0;
      }
    }

    for (; endPos < stringLen; ++endPos) {
      result += '\ufffd';
    }

    return result;
  };

  if (!keepBOM && inputString.substring(0, 3) === '\xEF\xBB\xBF') {
    inputString = inputString.substring(3);
  }

  return originalAtob(inputString).replace(/[\xc0-\xff][\x80-\xbf]*/g, replacer);
};
