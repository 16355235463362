/* istanbul ignore file */
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ApplicationWrapper } from 'flow-components';
import TranslateStore from '../../shared/store/translate/TranslateStore';
import LocaleStore from '../../shared/store/locale/LocaleStore';
import SsoStore from '../../shared/store/sso/SsoStore';
import { CoreConfig } from '../../shared/util/CoreConfig';

export interface IFlowComponentWrapperProps {
  children?: React.ReactChild | React.ReactChild[];
  translateStore: TranslateStore;
  localeStore: LocaleStore;
  ssoStore: SsoStore;
}

const FlowComponentWrapper = (props: IFlowComponentWrapperProps): JSX.Element => {
  const { children, ssoStore, localeStore, translateStore } = props;

  return (
    <ApplicationWrapper
      environment={CoreConfig.environment}
      /* eslint-disable-next-line import/no-internal-modules */
      frontLibrary={require('telia-front-react')}
      translations={translateStore.translations}
      brand="TELIA"
      /* eslint-disable-next-line import/no-internal-modules */
      icons={require('@telia-front/icon/lib/svg/icons.svg')}
      onLanguageChange={(locale: any) => localeStore.setLocale(locale as any)}
      sso={{
        isDealer: !!ssoStore.dealer,
        isLoggedIn: ssoStore.isLoggedInWithHardAuth,
        isLoggedInWithHardAuth: ssoStore.isLoggedInWithHardAuth,
        loggedInPerson: {
          customerId: ssoStore.customer?.customerId,
          registrationNumber: ssoStore.person?.personalCode,
          registrationNumberCountryCode: ssoStore.person?.personalCodeCountryCode,
        },
        representative: {
          customerId: ssoStore.customer?.customerId,
          registrationNumber: ssoStore.person?.personalCode,
          registrationNumberCountryCode: ssoStore.person?.personalCodeCountryCode,
          type: ssoStore.customer?.type as 'B2C' | 'B2B',
        },
      }}
    >
      {children}
    </ApplicationWrapper>
  );
};

export default inject('translateStore', 'localeStore', 'ssoStore')(observer(FlowComponentWrapper));
