export const ET_AREA_CODE = '372';

type MobileNumber = string | number | PhoneNumber;

export class PhoneNumber {
  value: number;

  constructor(value: string | number) {
    value = value.toString();
    value = value.replace(/\s/g, '');

    if (value.startsWith(ET_AREA_CODE)) {
      value = value.substring(ET_AREA_CODE.length);
    }

    this.value = Number(value);
  }

  get isValid(): boolean {
    return !Number.isNaN(this.value);
  }

  toString(): string {
    return this.value.toString().replace(/(\d+)(\d{4})/, '$1 $2');
  }
}

export const isEstonianMobileNumber = (value: any): value is MobileNumber => {
  if (value instanceof PhoneNumber) {
    value = value.value;
  }

  if (typeof value !== 'string' && typeof value !== 'number') {
    return false;
  }

  const sanitized = sanitizeEstonianMobileNumber(value);
  const isNumericWithAcceptedLength = /^[0-9]{6,12}$/.test(sanitized);
  const startsWithAcceptedPrefix = /^[58][0-9]{1}/.test(sanitized);

  return isNumericWithAcceptedLength && startsWithAcceptedPrefix;
};

export const sanitizeEstonianMobileNumber = (value: MobileNumber): string => {
  if (value instanceof PhoneNumber) {
    value = value.value;
  }
  return value
    .toString()
    .replace(/\s/g, '')
    .replace(/^((\+|00)?372)/, '');
};
