import { ActionCode } from '../ActionCode';
import { AllowedActionLook, AllowedActionPlacement, AllowedActionResource, AllowedActionUrl } from './AllowedAction';

export class ProductAllowedAction {
  allowedActionResource: AllowedActionResource;
  action: ActionCode;
  name: string;
  url: AllowedActionUrl | null;
  icon: string | null;
  look: AllowedActionLook;
  placement: AllowedActionPlacement;
  external = false;
  openInModal = false;
  dealer: boolean;
  customerChangeNeeded: boolean;
  orderBy: number;
  onClick?: (event: { preventDefault: () => void }) => void;

  static createNoActionsAction(name: string): ProductAllowedAction {
    const noActionsAction = new ProductAllowedAction({
      action: ActionCode.NO_ACTIONS,
      name,
      url: null,
      icon: '#alert',
      look: 'LINK',
      placement: 'GROUPED',
      external: false,
      openInModal: false,
      dealer: false,
      customerChangeNeeded: false,
      orderBy: 1,
    });
    noActionsAction.onClick = (event) => event.preventDefault();
    return noActionsAction;
  }

  constructor(data: AllowedActionResource) {
    Object.assign(this, data);
    this.allowedActionResource = data;
  }

  get target(): string {
    return this.external ? '_blank' : '';
  }

  get type() {
    if (this.dealer) {
      return 'employee';
    }

    return this.look === 'ORDER' ? 'expressive' : undefined;
  }

  get actionIcon(): string {
    return this.icon || '#file-edit';
  }

  get shouldDisplayActionIcon(): boolean {
    return this.look === 'LINK';
  }

  get iconLeft(): string | undefined {
    return this.shouldDisplayActionIcon ? this.actionIcon : undefined;
  }

  get iconRight(): string | undefined {
    return this.shouldDisplayActionIcon ? undefined : '#arrow-right';
  }
}
