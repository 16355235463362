import { isAfterNow, isBeforeNow } from '../util/dateUtil';
import { PriceType } from '../model/PriceType';

export interface ProductDetailsPriceResource {
  description: string;
  endDate: string | null;
  priceBillingReferenceCode: string;
  priceCode: string;
  priceType: PriceType;
  priceWithVat: number;
  priceWithoutVat: number;
  productOfferingPriceId: number;
  quantity: number;
  startDate: string | null;
  state: string;
  subPrices: ProductPriceResource[];
  unit: string;
  userIdValue: string;
}

export interface ProductPriceResource extends ProductDetailsPriceResource {
  userId: number | null;
  applicable: boolean | null;
  algorithm: boolean | null;
  valid: boolean | null;
  unitType: string | null;
  productOfferingPriceIdRef: null | number;
  calculate: boolean | null;
  nextPrices: any[] | null;
  vatPercentage: number | null;
  replacesProductOfferingPriceId: number | null;
  algorithmType: string | null;
  priceAccountType: string | null;
  priceValueBillingReferenceCode: string | null;
}

export class ProductPrice {
  unit: string;
  priceWithVat: number;
  priceWithoutVat: number;
  priceCode: string;
  state: string;
  priceType: PriceType;
  subPrices: ProductPrice[] = [];
  startDate: string;
  endDate: string;

  constructor(data: ProductPriceResource | ProductDetailsPriceResource) {
    Object.assign(this, {
      ...data,
      subPrices: (data.subPrices || []).map((subPriceJson) => new ProductPrice(subPriceJson)),
    });
  }

  get isRecurring(): boolean {
    return this.priceType === 'RECURRING_FEE';
  }

  get isActive(): boolean {
    return this.state === 'ACTIVE';
  }

  get isNonZero(): boolean {
    return this.priceWithoutVat > 0 && this.priceWithVat > 0;
  }

  get isValid(): boolean {
    return (!this.startDate || isBeforeNow(this.startDate)) && (!this.endDate || isAfterNow(this.endDate));
  }

  get discountPrices(): ProductPrice[] {
    return this.subPrices.filter((subPrice) => subPrice.priceType === 'DISCOUNT');
  }

  get validDiscountPrice(): ProductPrice | undefined {
    return this.discountPrices.find((discountPrice) => discountPrice.isValid);
  }

  get hasValidDiscount(): boolean {
    return !!this.validDiscountPrice;
  }

  get discountPriceWithVat(): number {
    return this.priceWithVat + (this.validDiscountPrice ? this.validDiscountPrice.priceWithVat : 0);
  }

  get discountPriceWithoutVat(): number {
    return this.priceWithoutVat + (this.validDiscountPrice ? this.validDiscountPrice.priceWithoutVat : 0);
  }

  get futureDiscountPrices(): ProductPrice[] {
    return this.discountPrices.filter((discountPrice) => discountPrice.startDate && isAfterNow(discountPrice.startDate));
  }
}
