import { action, computed, observable, makeObservable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { RouterStore, syncHistoryWithStore, SynchronizedHistory } from '@superwf/mobx-react-router';
import { createBrowserHistory } from 'history';
import { pathToRegexp } from 'path-to-regexp';
import { parse } from 'qs';
import { NavigationEvent } from 'telia-megamenu';
import RouteKey from '../../model/navigation/RouteKey';
import ConfigApi from '../../service/config/ConfigApi';
import LocaleStore from '../locale/LocaleStore';
import SsoStore from '../sso/SsoStore';
import FeatureStore from '../feature/FeatureStore';
import RouteProps, { PathType } from '../../model/navigation/RouteProps';
import { ExternalLink, ExternalLinks } from '../../model/ExternalLink';

export interface INavigationStoreContext {
  localeStore: LocaleStore;
  ssoStore: SsoStore;
  featureStore: FeatureStore;
}

class NavigationStore {
  static PARAM_NAME_PRODUCT_ID = 'productId';
  static PARAM_NAME_ORIGIN = 'origin';
  static PARAM_NAME_PRODUCT_AND_ORIGIN = 'productIdAndOrigin';
  static REQUIRED_PRODUCT_ID = `/:${NavigationStore.PARAM_NAME_PRODUCT_ID}([1-9]\\d*)`;
  static REQUIRED_ORIGIN = `/:${NavigationStore.PARAM_NAME_ORIGIN}([A-Z_]+)`;
  static REQUIRED_PRODUCT_ID_AND_ORIGIN = `/:${NavigationStore.PARAM_NAME_PRODUCT_AND_ORIGIN}([1-9]\\d*_[A-Z_]+)`;
  static OPTIONAL_PRODUCT_ID = `${NavigationStore.REQUIRED_PRODUCT_ID}?`;
  static OPTIONAL_ORIGIN = `${NavigationStore.REQUIRED_ORIGIN}?`;
  static OPTIONAL_PRODUCT_ID_AND_ORIGIN = `/:${NavigationStore.PARAM_NAME_PRODUCT_AND_ORIGIN}([1-9]\\d*_[A-Z_]+)?`;

  static SELF = '/';
  static SERVICES = '/teenused';
  static ORDER = '/tellimine';
  static BUSINESS = '/ari';
  static PRIVATE = '/era';
  static BILLS = '/arved';
  static OFFERS = '/pakkumised';
  static SETTINGS = '/seaded';
  static REGISTER = '/konto/registreerimine';
  static CUSTOMER_SEARCH = '/teenindus/otsing';
  static FROM_AGREEMENTS_SUFFIX = 'from=lepingud';
  static AGREEMENTS = '/lepingud';

  static B2C_SETTINGS = NavigationStore.PRIVATE + NavigationStore.SETTINGS;
  static B2B_SETTINGS = NavigationStore.BUSINESS + NavigationStore.SETTINGS;

  static MOBILE_ADDITIONAL_SERVICES = `${NavigationStore.ORDER}/mobiil${NavigationStore.REQUIRED_PRODUCT_ID_AND_ORIGIN}/lisateenused`;
  static TV = `${NavigationStore.ORDER}/tv`;
  static MULTI_CLICK_ORDER_TV_CHANNELS = `${NavigationStore.ORDER}/tv-lisakanalid${NavigationStore.OPTIONAL_PRODUCT_ID}`;
  static MULTI_CLICK_ORDER_TV_EXTRA_SERVICES = `${NavigationStore.ORDER}/tv-lisateenused${NavigationStore.OPTIONAL_PRODUCT_ID}${NavigationStore.OPTIONAL_ORIGIN}`;
  static MULTI_CLICK_ORDER_INTERNET_EXTRA_SERVICES = `${NavigationStore.ORDER}/internet${NavigationStore.REQUIRED_PRODUCT_ID}${NavigationStore.REQUIRED_ORIGIN}/staatiline-ip`;

  static TECHNICIAN = `${NavigationStore.SERVICES}/minutehnik`;
  static DEVICE = `${NavigationStore.SERVICES}/seadmed`;
  static DEVICE_FROM_AGREEMENTS = `${NavigationStore.DEVICE}?${NavigationStore.FROM_AGREEMENTS_SUFFIX}`;

  static B2C_SETTINGS_AGREEMENTS = `${NavigationStore.B2C_SETTINGS}/lepingud`;
  static B2C_DEVICE_INSURANCE = `${NavigationStore.PRIVATE}/seadmed/seadmekindlustus`;
  static B2B_SETTINGS_AGREEMENTS = `${NavigationStore.B2B_SETTINGS}/lepingud`;
  static B2B_DEVICE_INSURANCE = `${NavigationStore.BUSINESS}/seadmed/seadmekindlustus`;

  static SERVICE_AGREEMENTS = `${NavigationStore.AGREEMENTS}/teenuste-lepingud`;

  static OFFERS_TELIA1 = `${NavigationStore.OFFERS}/telia1`;

  private router: RouterStore;

  @observable routeKey: RouteKey;
  @observable externalLinks: Map<ExternalLink, string> = new Map();

  private static setInitialHistoryState(): void {
    const state = { spaView: true };
    window.history.replaceState({ state }, '');
  }

  constructor(private routes: RouteProps[]) {
    makeObservable(this);
    this.router = new RouterStore();
    syncHistoryWithStore(createBrowserHistory(), this.router);
    this.history.subscribe(this.handleRouteChange);
  }

  init(): void {
    NavigationStore.setInitialHistoryState();
    this.fetchExternalLinks();
  }

  getMatchingRouteProps(pathname: string): RouteProps | undefined {
    return this.routes.find((routeProps) => pathToRegexp(routeProps.path).test(pathname));
  }

  onPathChange = (path: NavigationEvent): void => {
    path.preventDefault();
    this.navigateTo(path.currentTarget);
  };

  isExternalPath(url: string | URL): boolean {
    if (typeof url === 'string') {
      url = new URL(url, window.location.origin);
    }

    return url.origin !== window.location.origin || !this.getMatchingRouteProps(url.pathname);
  }

  navigateTo = (url: string | URL, target?: string, replace = false, spaView = true): void => {
    if (target) {
      window.open(url.toString(), target);
      return;
    }

    if (typeof url === 'string') {
      url = new URL(url, window.location.origin);
    }

    if (this.isExternalPath(url)) {
      window.location.href = url.toString();
      return;
    }

    url = url.toString().replace(url.origin, '');

    if (url !== this.currentUrl) {
      this.router.history[replace ? 'replace' : 'push'](url, { spaView });
    }
  };

  navigateBack = (): void => this.router.history.goBack();

  redirectToPublicFrontPage = (): void => {
    this.navigateTo(this.getExternalLink(ExternalLink.TELIAWEB));
  };

  matchesCurrentPaths(routePath: PathType): boolean {
    if (Array.isArray(routePath)) {
      return routePath.reduce((prevMatch: boolean, path: string) => {
        const nextMatch = this.matchesCurrentPath(path);
        return prevMatch || nextMatch;
      }, false);
    }
    return this.matchesCurrentPath(routePath);
  }

  matchesCurrentPath(routePath: string): boolean {
    if (!routePath.startsWith(LocaleStore.pathLocalePrefixPattern)) {
      routePath = LocaleStore.pathLocalePrefixPattern + routePath;
    }

    return pathToRegexp(routePath).test(this.router.location.pathname);
  }

  getExternalLink(key: ExternalLink, path?: string): string {
    return ((this.externalLinks && this.externalLinks.get(key)) || '') + (path || '');
  }

  findUrlParamValue(param: string): string | undefined {
    const queryString: { [key: string]: any | undefined } = parse(this.router.location.search, { ignoreQueryPrefix: true });
    return queryString[param];
  }

  findUrlParamValueAsArray(param: string): string[] {
    let paramValueArr: string[] = [];
    const paramValue = this.findUrlParamValue(param);
    if (!!paramValue) {
      paramValueArr = paramValue.split(',');
    }
    return paramValueArr;
  }

  @computed
  get location() {
    return this.router.location;
  }

  @computed
  get history() {
    return this.router.history as SynchronizedHistory;
  }

  @computed
  get isBusinessContext(): boolean {
    const queryString = parse(this.router.location.search, { ignoreQueryPrefix: true });
    return queryString['context'] === 'business';
  }

  @computed
  get routeParams(): { [key: string]: any } | undefined {
    if (!this.currentRouteProps) {
      return undefined;
    }

    const { pathname } = this.router.location;
    const { path } = this.currentRouteProps;
    const match = matchPath(pathname, { path });

    return match?.params;
  }

  @computed
  private get currentRouteProps(): RouteProps | undefined {
    const { pathname } = this.router.location;
    return this.getMatchingRouteProps(pathname);
  }

  getRouteParam(paramName: string): string | undefined {
    const params = this.routeParams;
    if (!params) {
      return undefined;
    }

    const param = params[paramName];
    if (!param) {
      return undefined;
    }
    return param;
  }

  @computed
  private get currentUrl(): string {
    const { pathname, search = '', hash = '' } = this.router.location;
    return pathname + search + hash;
  }

  @action
  private setExternalLinks(response: ExternalLinks): void {
    this.externalLinks = new Map(Object.entries(response) as Array<[ExternalLink, string]>);
  }

  @action
  private handleRouteChange = (): void => {
    this.routeKey = this.currentRouteProps ? this.currentRouteProps.key : RouteKey.NOT_FOUND;
  };

  private async fetchExternalLinks(): Promise<void> {
    return ConfigApi.getExternalLinks()
      .then((response) => this.setExternalLinks(response))
      .catch(() => console.warn('Failed to fetch environment based links '));
  }
}

export default NavigationStore;
