export enum CatalogCode {
  TARNE_V2 = 'TARNE_V2',
  ARITEENUSED_ARI_WEB = 'ARITEENUSED_ARI_WEB',
  KODUTEENUSED_ERA_WEB = 'KODUTEENUSED_ERA_WEB',
  MINU_TEENUSED_KONTOR_ARI = 'MINU_TEENUSED_KONTOR_ARI',
  MINU_TEENUSED_KODU_ERA = 'MINU_TEENUSED_KODU_ERA',
  MINU_TEENUSED_LISAD_ERA = 'MINU_TEENUSED_LISAD_ERA',
  MINU_TEENUSED_MOBIIL_ERA = 'MINU_TEENUSED_MOBIIL_ERA',
  MINU_TEENUSED_MOBIIL_ARI = 'MINU_TEENUSED_MOBIIL_ARI',
  MINU_TEENUSED_HOSTING_ERA = 'MINU_TEENUSED_HOSTING_ERA',
  MINU_TEENUSED_HOSTING_ARI = 'MINU_TEENUSED_HOSTING_ARI',
  MINU_TEENUSED_LISAD_ARI = 'MINU_TEENUSED_LISAD_ARI',
  MOBIILI_PAKETID = 'MOBIILI_PAKETID',
  MINU_TEENUSED_IT_TEENUSED_ARI = 'MINU_TEENUSED_IT_TEENUSED_ARI',
  MINU_TEENUSED_FINANTS_ERA = 'MINU_TEENUSED_FINANTS_ERA',
  TELIA_PLAY = 'TELIA_PLAY',
  MINU_TEENUSED_SEADMED_ARI = 'MINU_TEENUSED_SEADMED_ARI',
  MINU_TEENUSED_SEADMED_ERA = 'MINU_TEENUSED_SEADMED_ERA',
  SEADMEKINDLUSTUS_ERA = 'SEADMEKINDLUSTUS_ERA',
  SEADMEKINDLUSTUS_ARI = 'SEADMEKINDLUSTUS_ARI',
  MINU_TEENUSED = 'MINU_TEENUSED',
  ERA_ARVE_LISATEENUSED = 'ERA_ARVE_LISATEENUSED',
  FIX_WIRELESS_INTERNET_ERA = 'FIX_WIRELESS_INTERNET_ERA',
  FIX_WIRELESS_INTERNET_ARI = 'FIX_WIRELESS_INTERNET_ARI',
}
