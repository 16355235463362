import { CookieUtil } from './CookieUtil';
import { CoreConfig } from './CoreConfig';

export class ErrorUtil {
  static pushError(error: Error): string | undefined {
    if (CoreConfig.isNative) {
      return;
    }
    const traceId = this.pushErrorToTeliaRumAndGetTransaction(error);
    console.error(error, traceId);
    return traceId;
  }

  static pushErrorToTeliaRumAndGetTransaction(error: Error, message?: string): string {
    let traceId: string | undefined;
    if (window.TeliaRum) {
      traceId = window.TeliaRum.sendError(error, message);
    }
    return `tx:${traceId}:ut:${this.getTeliaRumUserTrackerCookie()}`;
  }

  static getTeliaRumUserTrackerCookie(): string | undefined {
    return CookieUtil.getCookieValue('rum_session_id');
  }
}
