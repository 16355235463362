import { computed, makeObservable } from 'mobx';
import SsoStore from '../sso/SsoStore';
import ProductUserStore from '../productUser/ProductUserStore';
import { Origin } from '../../model/Origin';
import { Mandate, MandateType } from '../../model/Sso';

class MandateCheckerStore {
  constructor(
    private rootStore: {
      ssoStore: SsoStore;
      productUserStore: ProductUserStore;
    }
  ) {
    makeObservable(this);
  }

  @computed
  get isLoadingMandates() {
    return !this.rootStore.ssoStore.loaded;
  }

  @computed
  get mandates(): Mandate[] {
    return this.rootStore.ssoStore.mandates || [];
  }

  @computed
  private get legalMandates(): Mandate[] {
    return this.getMandatesByType('LEGAL');
  }

  @computed
  private get billingMandates(): Mandate[] {
    return this.getMandatesByType('BILLING');
  }

  @computed
  private get serviceMandates(): Mandate[] {
    return this.getMandatesByType('SERVICE');
  }

  private getMandatesByType(type: MandateType): Mandate[] {
    return this.mandates.filter(({ mandate }) => mandate === type);
  }

  private getMandatesByTypes(types: MandateType[]): Mandate[] {
    return this.mandates.filter(({ mandate }) => types.includes(mandate));
  }

  @computed
  get hasLegalMandate() {
    return !!this.legalMandates.length;
  }

  @computed
  get hasServiceMandate() {
    return !!this.serviceMandates.length;
  }

  @computed
  get hasBillingMandate() {
    return !!this.billingMandates.length;
  }

  @computed
  get serviceMandateLimitedElionAccountIds(): string[] | undefined {
    const accountIds: string[] = [];

    for (const { elionAccounts } of this.serviceMandates) {
      accountIds.push(...this.getAccountIds(elionAccounts));
    }

    return accountIds.length ? accountIds : undefined;
  }

  @computed
  get serviceMandateLimitedAccountIds(): string[] | undefined {
    const accountIds: string[] = this.serviceMandateLimitedElionAccountIds?.slice() || [];

    for (const { emtAccounts } of this.serviceMandates) {
      accountIds.push(...this.getAccountIds(emtAccounts, Origin.TBCIS));
    }

    return accountIds.length ? accountIds : undefined;
  }

  private getAccountIds(accounts: number[] | null | undefined, prefix = ''): string[] {
    if (!accounts || !accounts.length || accounts.includes(-1)) {
      return [];
    }
    return accounts.map((account) => prefix + account.toString());
  }

  @computed
  get hasPageMandate(): boolean {
    return (this.hasLegalMandate || this.hasServiceMandate) && !this.hasDiilAccountOnly;
  }

  @computed
  get showLimitedMandateNotice(): boolean {
    if (this.hasLegalMandate || !this.hasServiceMandate) {
      return false;
    }

    const { elionAccounts } = this.serviceMandates[0];
    return elionAccounts === null || (Array.isArray(elionAccounts) && elionAccounts[0] !== -1);
  }

  @computed
  get hasServiceMandateWithoutAccounts(): boolean {
    if (!this.hasServiceMandate) {
      return false;
    }

    const { elionAccounts, emtAccounts } = this.serviceMandates[0];
    return elionAccounts === null && emtAccounts === null;
  }

  @computed
  get hasLegalOrServiceMandate(): boolean {
    return this.hasLegalMandate || this.hasServiceMandate;
  }

  @computed
  get hasLegalOrBillingMandateWithoutAccountsLimit(): boolean {
    return this.hasLegalMandate || this.hasBillingMandateWithoutAccountsLimit;
  }

  @computed
  get hasLegalOrServiceOrBillingMandate(): boolean {
    return this.hasLegalOrServiceMandate || this.hasBillingMandate;
  }

  @computed
  get hasRequiredMandatesForCustomerManagers(): boolean {
    const REQUIRED_MANDATE_LIST: MandateType[] = ['LEGAL', 'SERVICE', 'BILLING', 'GOODS', 'LEASING', 'SERVICE_USAGE_DISTINCTION'];

    return this.mandates.some((mandate) => REQUIRED_MANDATE_LIST.includes(mandate.mandate));
  }

  @computed
  get hasDiilAccountOnly(): boolean {
    const { displayableProductUsers } = this.rootStore.productUserStore;

    if (this.hasLegalMandate || !!displayableProductUsers.length) {
      return false;
    }
    const mandates = this.getMandatesByTypes(['SERVICE', 'BILLING', 'GOODS', 'LEASING', 'SERVICE_USAGE_DISTINCTION']);
    return this.noMandatesOrHasMandateWithDiilAccountsLimitOnly(mandates);
  }

  @computed
  private get hasBillingMandateWithoutAccountsLimit(): boolean {
    if (!this.hasBillingMandate) {
      return false;
    }

    return this.billingMandates.some(this.hasMandateWithoutAccountsLimit);
  }

  private hasMandateWithoutAccountsLimit = (mandate: Mandate): boolean => {
    const { elionAccounts, emtAccounts } = mandate;

    return this.hasNoLimit(elionAccounts) && this.hasNoLimit(emtAccounts);
  };

  private noMandatesOrHasMandateWithDiilAccountsLimitOnly = (mandates: Mandate[]): boolean =>
    mandates.length === 0 || mandates.every(this.hasMandateWithDiilAccountsLimitOnly);

  private hasMandateWithDiilAccountsLimitOnly = (mandate: Mandate): boolean => {
    const { elionAccounts, emtAccounts, diilAccounts } = mandate;

    return !elionAccounts?.length && !emtAccounts?.length && !!diilAccounts?.length;
  };

  private hasNoLimit = (accounts: number[] | null | undefined): boolean => {
    const noLimitPredicate = (element: number) => element === -1;

    return !!accounts && accounts.some(noLimitPredicate);
  };
}

export default MandateCheckerStore;
