import { PhoneNumber } from './PhoneNumber';

export const formatCommunicationNumber = (communicationNumbers: string | string[] | undefined): string => {
  const numbers = (communicationNumbers instanceof Array ? communicationNumbers : [communicationNumbers]).filter((c) => c);
  if (!numbers.length || numbers[0] === undefined) {
    return '';
  }

  const phoneNumber = new PhoneNumber(numbers[0] as string);

  return phoneNumber.isValid ? phoneNumber.toString() : numbers[0];
};
