import { CustomerContract, CustomerContractState } from '../../model/crm/CustomerContract';
import { Contact } from '../../store/crm/CrmStore';
import { Person } from '../../model/crm/Person';
import { API } from '../../util/apiUtil';

export type CustomerCharacteristic = { name: string };

export const CRM_API_BASE_URL = '/sales-api/crm';

class CrmApi {
  static getContactInfo = () => API.get<Contact>(`${CRM_API_BASE_URL}/contacts`).then((response) => response.data);

  static getCustomerContracts = (customerId: number, stateCode?: CustomerContractState) =>
    API.get<CustomerContract[]>(`${CRM_API_BASE_URL}/contracts/${customerId}`, { params: { stateCode } }).then((response) => response.data);

  static getPerson = (personalCode: string, personalCodeCountryCode: string) =>
    API.get<Person>(`${CRM_API_BASE_URL}/persons/personal-code/${personalCode}/country-code/${personalCodeCountryCode}`).then(
      (response) => response.data as Person
    );

  static getCustomerCharacteristics = (customerId: number) =>
    API.get<{ customerCharacteristics: CustomerCharacteristic[] }>(
      `${CRM_API_BASE_URL}/customer-characteristics/customers/${customerId}`
    ).then((response) => response.data);
}

export default CrmApi;
