export const MessageKey = {
  DELETE_PRODUCT_REQUEST_SUCCESS: 'DELETE_PRODUCT_REQUEST_SUCCESS',
  DELETE_PRODUCT_REQUEST_FAIL: 'DELETE_PRODUCT_REQUEST_FAIL',
  INTERNET_MOVE_REQUEST_SUCCESS: 'INTERNET_MOVE_REQUEST_SUCCESS',
  INTERNET_MOVE_REQUEST_FAIL: 'INTERNET_MOVE_REQUEST_FAIL',
  DEVICE_TERMINATION_SUCCESS_WITH_TECHNICIAN: 'deviceTerminate-technician-time-unset.confirmSuccess',
  DEVICE_TERMINATION_SUCCESS_WITH_TECHNICIAN_WHEN_DEVICE_RETURNED:
    'deviceTerminate-technician-time-unset.confirmSuccess.when.device.returned',
  DEVICE_TERMINATION_SUCCESS_WITH_PARCEL_MACHINE: 'deviceTerminate-parcel-machine.confirmSuccess',
  DEVICE_TERMINATION_SUCCESS_WITH_PENALTY_WARNING: 'deviceTerminate-penalty.confirmSuccess',
  DEVICE_TERMINATION_SUCCESS: 'deviceTerminate.confirmSuccess',
  DEVICE_TERMINATION_PENALTY: 'deviceTerminate-penalty',
  DEVICE_TERMINATION_NO_PENALTY: 'deviceTerminate-no-penalty',
  DEVICE_TERMINATION_PENALTY_SMART_WIFI: 'deviceTerminate-penalty-smart-wifi',
  DEVICE_TERMINATION_TECHNICIAN_PENALTY: 'deviceTerminate-technician-time-unset',
  DEVICE_TERMINATION_TECHNICIAN_PENALTY_WHEN_DEVICE_RETURNED: 'deviceTerminate-technician-time-unset.when.device.returned',
  DEVICE_TERMINATION_PARCEL_MACHINE_PENALTY: 'deviceTerminate-parcel-machine',
  EMPLOYEE_OFFERINGS: 'employeeOfferings',
  DEFAULT_OFFERINGS: 'defaultOfferings',
  COUPONS_APPLIED_SUCCESS: 'couponApplied',
  COUPONS_APPLIED_FAIL: 'invalidCoupon',
  COUPON_REQUIRED: 'coupon-required',
  CONFIRM_SUCCESS: 'confirmSuccess',
  CONFIRM_BASKET_NOTIFICATIONS: 'CONFIRM_BASKET_NOTIFICATIONS',
  CONFIRM_BASKET_DEBT_TOO_LARGE: 'DEBT_TOO_LARGE',
  CONFIRM_BASKET_NEGATIVE_CREDIT_RATING: 'NEGATIVE_CREDIT_RATING',
  CONFIRM_BASKET_CUSTOMER_CONTRACT_NOT_SIGNED: 'CUSTOMER_CONTRACT_NOT_SIGNED',
  CONFIRM_BASKET_CUSTOMER_STATE_CLOSED: 'CUSTOMER_STATE_CLOSED',
  CONFIRM_BASKET_RUNNING_ORDER_FOUND_FOR_POSTED_ORDER: 'RUNNING_ORDER_FOUND_FOR_POSTED_ORDER',
  CONFIRM_BASKET_DEVICE_INSURANCE_POLICY_ILLEGAL_STATE: 'DEVICE_INSURANCE_POLICY_ILLEGAL_STATE',
  PICK_AND_MIX: 'PICK_AND_MIX',
  TV_CHANNEL_REQUEST_FAIL: 'TV_CHANNEL_REQUEST_FAIL',
  AUTOMATIC_CUSTOMER_CHANGE_FAIL: 'AUTOMATIC_CUSTOMER_CHANGE_FAIL',
  MANAGE_USER_DATA_SUCCESS: 'MANAGE_USER_DATA_SUCCESS',
  AFTER_HEADER: 'AFTER_HEADER',
  PRODUCT_ORDERS_FINISHED: 'PRODUCT_ORDERS_FINISHED',
  SECURE_INTERNET_REPORT: 'extras-secure-report',
};
