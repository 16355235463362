import { PhoneUsage } from '../Usage';
import { PhoneUsageResource } from './usage/Usage';

export class SmsUsage extends PhoneUsage {
  used = this.resource.used;
  remaining = this.resource.remaining;
  limit = this.resource.limit;

  constructor(resource: PhoneUsageResource) {
    super(resource, resource.includesTariffClasses);
  }
}
