import { Environment } from '../model/config/Environment';

export const getPubSubUrl = (environment: Environment) => {
  switch (environment) {
    case 'local':
      return 'wss://localhost:8080/ws/customer-products';
    case 'dev':
      return 'wss://iseteenindus-dev.telia.ee/ws/customer-products';
    case 'test':
      return 'wss://iseteenindus-test.telia.ee/ws/customer-products';
    case 'live':
      return 'wss://iseteenindus.telia.ee/ws/customer-products';
    default:
      return assertNever(environment);
  }
};

/**
 * If a new value is added to the `Environment` union type but corresponding case is not added to switch then a type error is raised compile time.
 * If a new value appears at runtime then a runtime exception is thrown.
 */
const assertNever = (environment: never) => {
  throw new Error(`Unknown environment: "${environment}"`);
};
