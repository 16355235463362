import { autorun, computed, reaction, makeObservable } from 'mobx';
import { AuthRedirectContext } from '../../model/AuthRedirectContext';

export const GOTO_URL_PARAM_NAME = 'auth-goto';
export const AUTH_LEVEL_PARAM_NAME = 'auth-level';

export class AuthRedirectStore {
  private reactions: Function[] = [];

  constructor(private readonly context: AuthRedirectContext) {
    makeObservable(this);
  }

  init() {
    const { ssoStore, navigationStore } = this.context;
    this.terminate();
    this.reactions = [
      autorun(() => ssoStore.toggleSignIn(this.isSignInNecessary)),
      autorun(() => this.isReadyToRedirect && navigationStore.navigateTo(this.gotoUrl!)),
      reaction(() => ssoStore.isSignInInvoked, this.cancelAuthRedirect),
    ];
  }

  terminate() {
    this.reactions.forEach((dispose) => dispose());
  }

  @computed
  get isAuthRedirectInProgress() {
    return !!this.gotoUrl;
  }

  @computed
  private get gotoUrl(): string | undefined {
    const { navigationStore } = this.context;
    const gotoUrlParam = navigationStore.findUrlParamValue(GOTO_URL_PARAM_NAME);
    return gotoUrlParam && navigationStore.isExternalPath(gotoUrlParam) ? gotoUrlParam : undefined;
  }

  @computed
  private get isSignInNecessary(): boolean {
    return !!this.gotoUrl && !this.context.ssoStore.isLoggedInWithHardAuth;
  }

  @computed
  private get isReadyToRedirect(): boolean {
    return !!this.gotoUrl && this.context.ssoStore.isLoggedInWithHardAuth;
  }

  private cancelAuthRedirect = (isSignInInvoked: boolean) => {
    if (!this.gotoUrl || isSignInInvoked || this.context.ssoStore.isLoggedInWithHardAuth) {
      return;
    }

    this.context.navigationStore.redirectToPublicFrontPage();
  };
}
