import { isOrigin } from '../util/isOrigin';
import { Origin } from '../model/Origin';

export interface ProductReference {
  productId: number;
  origin: Origin;
}

export class ProductRef implements ProductReference {
  readonly productId: number;
  readonly origin: Origin;

  static fromProductIdAndOrigin(productIdAndOrigin: string): ProductRef {
    const indexOfUnderscore = productIdAndOrigin.indexOf('_');
    const productId = parseInt(productIdAndOrigin.substring(0, indexOfUnderscore), 10);
    const origin = productIdAndOrigin.substring(indexOfUnderscore + 1);

    if (isNaN(productId) || productId < 1) {
      throw new Error(
        `Parsed productId "${productId}" from given productIdAndOrigin "${productIdAndOrigin}" is invalid. Valid product ID is a positive integer`
      );
    }

    if (!isOrigin(origin)) {
      throw new Error(
        `Parsed origin "${origin}" from given productIdAndOrigin "${productIdAndOrigin}" is invalid. Valid origins are: ${Object.values(
          Origin
        ).join(', ')}`
      );
    }

    return new ProductRef({ productId, origin });
  }

  constructor({ productId, origin }: ProductReference) {
    this.productId = productId;
    this.origin = origin;
  }

  get productIdAndOrigin(): string {
    return `${this.productId}_${this.origin}`;
  }
}
