import { PhoneUsageResource } from '../model/customerProduct/usage/Usage';
import { PhoneUsage } from '../model/Usage';

export class VoiceUsage extends PhoneUsage {
  used = Math.round(this.resource.used / 60);
  remaining = Math.round(this.resource.remaining / 60);
  limit = Math.round(this.resource.limit / 60);

  constructor(resource: PhoneUsageResource) {
    super(resource, resource.includesTariffClasses);
  }

  get labelKey(): string {
    return this.includesTariffClasses.length ? super.labelKey + '-total' : super.labelKey;
  }
}
