export class CookieUtil {
  static domain = '.telia.ee';
  static getCookieValue = (name: string) => {
    const pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (!!pair) {
      return pair[1];
    }
    return undefined;
  };

  static setCookie = (name: string, value: string, expires?: string, domain: string | null = CookieUtil.domain) => {
    const build = (key: string, cookieValue: string | undefined) => (cookieValue !== undefined ? `${key}=${cookieValue}; ` : '');
    const cookieString = (
      build(name, value) +
      build('expires', expires) +
      build('domain', domain === null ? undefined : domain) +
      build('path', '/')
    ).trim();
    document.cookie = cookieString;
  };

  static setIsFromSelfServiceCookie = () => {
    this.setCookie('isFromSelfService', 'true', undefined, '.telia.ee');
  };
}
