/* istanbul ignore next */
import { CustomerProductsHolderResource } from '../CustomerProductsHolder';
import CustomerProduct from './CustomerProduct';

export default class CustomerProductsHolder {
  products: CustomerProduct[];
  hasNextPage: boolean;

  constructor(json: CustomerProductsHolderResource) {
    this.hasNextPage = json.hasNextPage;
    this.products = json.products.map((product) => new CustomerProduct(product));
  }
}
