// eslint-disable-next-line import/no-internal-modules
import { action, computed, makeObservable, observable } from 'mobx';
import { pathToRegexp } from 'path-to-regexp';
import NavigationStore from '../navigation/NavigationStore';
import { CoreConfig } from '../../util/CoreConfig';
import { CookieUtil } from '../../util/CookieUtil';
import { ErrorUtil } from '../../util/ErrorUtil';
import { Locale } from '../../model/config/Locale';

enum LanguageIdToLocaleMap {
  et_EE = 'et',
  ru_RU = 'ru',
  en_GB = 'en',
}

export default class LocaleStore {
  static guestLanguageCookieName = 'LANGUAGE_EXT';
  static defaultLocale: Locale = 'et';
  static locales: Locale[] = ['et', 'ru', 'en'];
  static pathLocalePrefixPattern = `/:lang(${LocaleStore.locales.join('|')})?`;
  static pathLocaleRegexp = pathToRegexp(LocaleStore.pathLocalePrefixPattern + '/(.*)?');

  @observable currentLocale: Locale;

  static parsePath(path: string): { locale: Locale; pathname: string } {
    const matches: any = LocaleStore.pathLocaleRegexp.exec(path) || [];

    return {
      locale: matches[1] as Locale,
      pathname: matches[2] ? `/${matches[2]}` : '/',
    };
  }

  static getPathWithoutLocale(path: string): string {
    return LocaleStore.parsePath(path).pathname;
  }

  constructor(
    private rootStore: {
      navigationStore: NavigationStore;
    }
  ) {
    makeObservable(this);
  }

  init(): void {
    this.setLocale(this.localeInCookie || this.localeInLocation);
    this.rootStore.navigationStore.history.subscribe((location, historyAction): void => {
      /* istanbul ignore next */
      if (historyAction === 'PUSH' || historyAction === 'REPLACE') {
        this.setLocaleInLocation(this.currentLocale);
      }
    });
  }

  get localeInCookie(): Locale | undefined {
    const languageId = CookieUtil.getCookieValue(LocaleStore.guestLanguageCookieName);
    /* istanbul ignore next */
    if (!languageId) {
      return undefined;
    }
    const localeInCookie: Locale = LanguageIdToLocaleMap[languageId];

    return localeInCookie;
  }

  @computed
  get localeInLocation(): Locale {
    const { pathname } = this.rootStore.navigationStore.location;

    return LocaleStore.parsePath(pathname).locale || LocaleStore.defaultLocale;
  }

  @computed
  get currentPathWithoutLocale(): string {
    return LocaleStore.parsePath(this.rootStore.navigationStore.location.pathname).pathname;
  }

  @computed
  get currentLocalePathPrefix(): string {
    return this.getLocalePrefix(this.currentLocale);
  }

  @action
  setLocale = (locale: Locale): void => {
    if (locale !== this.currentLocale) {
      this.currentLocale = locale;
      this.setLanguageCookie(locale);
      this.setLocaleInLocation(locale);
      this.updateCookiebotCulture(locale);
      CoreConfig.currentLocale = locale;
    }
  };

  getPathWithCurrentLocale(path: string): string {
    return this.getPathWithLocale(path, this.currentLocale);
  }

  getCurrentPathWithLocale(locale: Locale): string {
    const { pathname } = this.rootStore.navigationStore.location;

    return this.getPathWithLocale(pathname, locale);
  }

  private getLocalePrefix(locale: Locale): string {
    return locale === LocaleStore.defaultLocale ? '' : `/${locale}`;
  }

  private getPathWithLocale(path: string, locale: Locale): string {
    return this.getLocalePrefix(locale) + LocaleStore.parsePath(path).pathname;
  }

  private setLocaleInLocation(locale: Locale): void {
    if (locale !== this.localeInLocation) {
      const { search = '', hash = '' } = this.rootStore.navigationStore.location;

      this.rootStore.navigationStore.navigateTo(this.getCurrentPathWithLocale(locale) + search + hash, undefined, true, false);
    }
  }

  private setLanguageCookie(locale: Locale): void {
    if (this.localeInCookie !== locale) {
      const languageId = Object.keys(LanguageIdToLocaleMap).find((key) => LanguageIdToLocaleMap[key] === locale);
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      /* istanbul ignore next */
      if (!languageId) {
        ErrorUtil.pushError(new Error(`Could not set language cookie for ${locale}`));
        return;
      }
      CookieUtil.setCookie(LocaleStore.guestLanguageCookieName, languageId, expirationDate.toUTCString());
    }
  }

  private updateCookiebotCulture(locale: Locale) {
    const cookiebotScript = document.querySelector<HTMLScriptElement>('#Cookiebot');
    if (cookiebotScript) {
      cookiebotScript.dataset.culture = locale;
    }
  }
}
