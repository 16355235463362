/* eslint-disable import/no-internal-modules */

import { Context } from '../model/config/Context';
import { Environment } from '../model/config/Environment';
import { Platform } from '../model/config/Platform';
import { Locale } from '../model/config/Locale';
import { TeliaOtelWebConfig } from '../model/config/TeliaOtelWebConfig';

/**
 * Configuration that never changes during code execution
 */
export interface StaticConfiguration {
  environment: Environment;
  platform: Platform;
  context: Context;
  teliaOtelWebConfig?: TeliaOtelWebConfig;
}

export interface DynamicConfiguration {
  locale?: Locale;
}

export type InitialStaticConfiguration = Omit<StaticConfiguration, 'platform'>;

export class CoreConfig {
  private static staticConfig: StaticConfiguration;
  private static dynamicConfig: DynamicConfiguration = {};

  private static isInitialized = false;

  static init(initialConfig: InitialStaticConfiguration) {
    if (CoreConfig.isInitialized) {
      this.ensureSameStaticConfiguration(initialConfig);
      return;
    }
    /* istanbul ignore next */
    CoreConfig.staticConfig = {
      platform: typeof window === 'undefined' ? 'node' : 'browser',
      ...initialConfig,
    };
    CoreConfig.isInitialized = true;
  }

  private static ensureSameStaticConfiguration(newConfig: InitialStaticConfiguration) {
    if (newConfig.environment !== CoreConfig.staticConfig.environment || newConfig.context !== CoreConfig.staticConfig.context) {
      throw new Error('CoreConfig.init with different config is not allowed');
    }
  }

  private static get staticConfiguration() {
    if (!CoreConfig.isInitialized) {
      throw new Error('Core configuration is not initialized');
    }
    return CoreConfig.staticConfig;
  }

  static get environment() {
    return this.staticConfiguration.environment;
  }

  static get teliaOtelWebConfig() {
    return this.staticConfiguration.teliaOtelWebConfig;
  }

  static get platform() {
    return this.staticConfiguration.platform;
  }

  static get context() {
    return this.staticConfiguration.context;
  }

  static get isWebview() {
    return this.context === 'webview';
  }

  static get isBrowser() {
    return this.context === 'browser';
  }

  static get isNative() {
    return this.context === 'native';
  }
  static get selfServiceUrl() {
    switch (this.environment) {
      case 'local':
      case 'dev':
        return 'https://iseteenindus-dev.telia.ee';
      case 'test':
        return 'https://iseteenindus-test.telia.ee';
      case 'live':
        return 'https://iseteenindus.telia.ee';
      default:
        throw new Error(`Unsupported environment '${CoreConfig.environment}'`);
    }
  }

  static set currentLocale(locale: Locale | undefined) {
    this.dynamicConfig.locale = locale;
  }

  static get currentLocale() {
    return this.dynamicConfig.locale;
  }
}
