import { CoreConfig } from './CoreConfig';

function getGrafanaRumScript() {
  if (CoreConfig.environment === 'local') return '';

  return `TeliaRum.init({
    beaconUrl: '${CoreConfig.teliaOtelWebConfig!.beaconUrl}',
    clientId: '${CoreConfig.teliaOtelWebConfig!.clientId}',
    clientToken: '${CoreConfig.teliaOtelWebConfig!.clientToken}',
    ignoreUrls: [
      /https:\\/\\/cat.telia.ee\\.*/,
      /https:\\/\\/cat.diil.ee\\.*/,
      /https:\\/\\/ccchat.estpak.ee\\/.*/,
      /https:\\/\\/www.google-analytics.com\\/.*/,
    ],
  });`;
}

function injectScript(scriptInnerHtml: string): void {
  const script = document.createElement('script');
  script.innerHTML = scriptInnerHtml;
  document.head.appendChild(script);
}

export function setGrafanaRum(): void {
  const agentScript = document.createElement('script');
  agentScript.src = CoreConfig.teliaOtelWebConfig!.rumAgent;
  document.head.appendChild(agentScript);
  // eslint-disable-next-line
  agentScript.addEventListener('load', function () {
    injectScript(getGrafanaRumScript());
  });
}
