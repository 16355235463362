/* istanbul ignore file  */

import { PhoneUsageResource, TariffClass, UsageResource } from './customerProduct/usage/Usage';

export abstract class Usage<T = number> {
  readonly used: T;
  readonly remaining: T;
  readonly limit: T;
  readonly tariffClass = this.resource.tariffClass;
  readonly limitType = this.resource.limitType;
  readonly usageType = this.resource.usageType;
  readonly usedPercentage = (this.resource.used / this.resource.limit) * 100;

  constructor(protected readonly resource: UsageResource) {}

  get labelKey() {
    return `my-services.mobility.tariff-class.${this.tariffClass}`;
  }
}

export abstract class PhoneUsage<T = number> extends Usage<T> {
  constructor(resource: PhoneUsageResource, readonly includesTariffClasses: TariffClass[] = []) {
    super(resource);
  }
}
