import { ApiResponse } from '../../api/ApiResponse';

export type DataUsageType = 'domestic' | 'eu';

export interface UsageResource {
  readonly tariffClass: TariffClass;
  readonly includedInTariffClass: TariffClass | null;
  readonly usageType: UsageType;
  readonly limitType: LimitType;
  readonly limit: number;
  readonly used: number;
  readonly remaining: number;
  readonly validUntil?: string;
}

export interface PhoneUsageResource extends UsageResource {
  readonly includesTariffClasses: TariffClass[];
}

export interface RoamingCountryResource {
  code: string;
  name?: Map<string, string>;
}

export interface RoamingUsages {
  country: ApiResponse<RoamingCountryResource>;
  usages?: Map<string, UsageResource>;
}

export interface UsageConsumptionResource {
  readonly numberDomesticDataUsage: UsageResource | null;
  readonly numberEuDataUsage: UsageResource | null;
  readonly groupDomesticDataUsage: UsageResource | null;
  readonly groupEuDataUsage: UsageResource | null;
  readonly voiceUsages: PhoneUsageResource[] | null;
  readonly smsUsages: PhoneUsageResource[] | null;
  readonly roamingUsages: RoamingUsages | null;
}

export enum UsageType {
  SMS = 'SMS',
  VOICE = 'VOICE',
  DATA = 'DATA',
}

export enum LimitType {
  QUOTA = 'QUOTA',
  UNLIMITED = 'UNLIMITED',
}

export enum TariffClass {
  DOMESTIC_DATA = 'DOMESTIC_DATA',
  DOMESTIC_DATA_DAY = 'DOMESTIC_DATA_DAY',
  DOMESTIC_DATA_GROUP = 'DOMESTIC_DATA_GROUP',
  DOMESTIC_DATA_MEMBER = 'DOMESTIC_DATA_MEMBER',
  FUP_DATA = 'FUP_DATA',
  FUP_DATA_GROUP = 'FUP_DATA_GROUP',
  FUP_DATA_MEMBER = 'FUP_DATA_MEMBER',
  E50 = 'E50',
  CALL_603 = '603',
  E62 = 'E62',
  E54 = 'E54',
  E51 = 'E51',
  SMS_604 = '604',
  E63 = 'E63',
  E64 = 'E64',
  E53 = 'E53',
  E41 = 'E41',
  E14 = 'E14',
  E21 = 'E21',
  SMS_638 = '638',
  E73 = 'E73',
  SMS_673 = '673',
  SMS_688 = '688',
  E16 = 'E16',
  E15 = 'E15',
  SMS_671 = '671',
  CALL_633 = '633',
  CALL_628 = '628',
  CALL_640 = '640',
  CAL = 'CAL',
  E25 = 'E25',
  E85 = 'E85',
  CALL_602 = '602',
  CALL_625 = '625',
  ROAMING_GDAY = 'GDAY',
  ROAMING_WEEK = 'WEEK',
}

export const euDataTariffClasses = [TariffClass.FUP_DATA, TariffClass.FUP_DATA_MEMBER, TariffClass.FUP_DATA_GROUP];
