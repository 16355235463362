const DATA_AMOUNT_MULTIPLIER = 1024;
const UNIT_SYMBOL_GB = 'GB';

export class DataAmount {
  gigabytes: number;

  private static roundToGigabytes(value: number, fractionDigits = 2): number {
    return +(value / Math.pow(DATA_AMOUNT_MULTIPLIER, 3)).toFixed(fractionDigits);
  }

  static convertKilobytesToBytes(kilobytes: number): number {
    return kilobytes * DATA_AMOUNT_MULTIPLIER;
  }

  constructor(public bytes: number) {
    this.gigabytes = DataAmount.roundToGigabytes(bytes);
  }

  toLocaleString(): string {
    return formatDataAmountValue(this.gigabytes);
  }

  /**
   * Even though the `amount` is in GiB we supplement "GB" unit symbol as per Telia pricing rules.
   */
  toString(): string {
    return this.toLocaleString().concat(` ${UNIT_SYMBOL_GB}`);
  }
}

const roundToMaxTwoDecimals = (number: number) => Math.round(number * 100) / 100;
const toCommaSeparated = (number: number) => number.toString().replace(/\./g, ',');
export const formatDataAmountValue = (number: number) => toCommaSeparated(roundToMaxTwoDecimals(number));
