import { Environment } from '../model/config/Environment';

const gtmId = 'GTM-5G2M3L';
const gtmSnippet = `window.dataLayer = [];
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;

interface IAnalyticsObject {
  event: string;
  page: string;
  group: string;
  id: string;
}

export class Analytics {
  static delimiter = ' - ';
  static defaultEvent = 'AutoEvent';
  static defaultCategory = 'GENERAL';
  static appName = 'Sales frontend';

  private static pushEnabled = true;

  static init(environment: Environment) {
    if (environment !== 'local') {
      const script = document.createElement('script');
      script.innerHTML = gtmSnippet;
      document.head.appendChild(script);
    }
  }

  static join = (...parts: Array<string | number | null | undefined>) => parts.filter(Boolean).join(Analytics.delimiter);

  static push = ({ event, page, id, group }: IAnalyticsObject) => {
    if (!Analytics.pushEnabled) {
      return;
    }
    const { dataLayer } = window as any;

    if (!dataLayer) {
      throw new Error('window.dataLayer must be defined. Have you injected the required Google Tag Manager script snippet?');
    }

    dataLayer.push({
      event,
      eventCategory: page,
      eventAction: group,
      eventLabel: id,
    });
  };

  static pushAbTestEvent = (name: string, variant: 'A' | 'B') => {
    if (window.dataLayer) {
      window.dataLayer.push({
        abVariation: `salesFrontend_${name}_${variant}`,
      });
    }
  };

  static enablePush = () => (Analytics.pushEnabled = true);
  static disablePush = () => (Analytics.pushEnabled = false);
}
